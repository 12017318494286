import React, { useContext, useEffect, useState } from 'react';
import { Divider, Grid } from '@mui/material';
import { DeleteIcon } from '../../../../icons/Delete/DeleteIcon';
import { VentoryColor } from '../../../../util/color.util';
import { t } from '../../../../../types/translation/Translator';
import { StockLocationContext } from '../../../../../context/StockLocationContext';
import MultiSelectionList from '../../../common/MultiSelectionList/MultiSelectionList';
import SearchBar from '../../../common/SearchBar/SearchBar';
import { StockLocation } from '../../../../../types/stockLocation';
import { removeDiacritics } from '../../../../../util/string.util';
import { countries } from '../../../../../util/country';
import ScrollableFilterContent from './ScrollableFilterContent';
import { FilterProps } from '../../Filter/common/filter.util';
import { CountryFilter } from '../../Filter/common/CountryFilter';

interface CountryFilterDropdownContentProps {
  filter: CountryFilter;
  filterProps: FilterProps;
  setFilterProps: (filter: FilterProps) => void;
}

export const countryFilter = (sl: StockLocation, text: string, countries: Map<string, any>) => {
  if (!sl.address?.countryCode) return false;

  const filter = removeDiacritics(text.toLowerCase());
  if (removeDiacritics(sl.address.countryCode.toLowerCase()).includes(filter)) return true;
  if (removeDiacritics(countries.get(sl.address.countryCode)?.name.common.toLowerCase()).includes(filter)) return true;
  return false;
};

export const countryMap = new Map(countries.map(i => [i.cca2, i]));

export default function CountryFilterDropdownContent({
  filter,
  filterProps,
  setFilterProps,
}: CountryFilterDropdownContentProps) {
  const { stockLocations } = useContext(StockLocationContext);

  const [textFilter, setTextFilter] = useState<string>('');

  const filterCountries = () => {
    return new Set(
      [...stockLocations.values()]
        .filter(sl => countryFilter(sl, textFilter, countryMap))
        .map(sl => sl?.address!.countryCode!),
    );
  };

  const [values, setValues] = useState<Set<string>>(filterCountries());

  const handleChange = (selected: Set<string>) => {
    filterProps.country = selected;
    if (filterProps.stockLocation) filterProps.stockLocation = new Set();
    if (!filterProps.country?.size) filterProps.country = undefined;
    setFilterProps({ ...filterProps });
  };

  const handleRemove = () => {
    filterProps.country = undefined;
    setFilterProps({ ...filterProps });
  };

  useEffect(() => {
    setValues(filterCountries());
  }, [textFilter]);

  return (
    <ScrollableFilterContent>
      <Grid container>
        <Grid item xs={12} className='flex py-2 px-3'>
          <Grid container>
            <Grid item>
              <p className='text-[13px] font-[500] text-ventory-grey-500'>{t().country.singular.label}</p>
            </Grid>
            <Grid item flexGrow={1} display={'flex'} justifyContent={'flex-end'} sx={{ color: VentoryColor.deleteRed }}>
              <Grid item onClick={handleRemove} className='cursor-pointer'>
                <DeleteIcon />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SearchBar onChange={i => setTextFilter(i)} className='pl-3 pr-4' />
        </Grid>
        <Grid item className='py-3' xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <MultiSelectionList
            selected={filterProps.country}
            values={values}
            onChange={handleChange}
            toText={id => countryMap.get(id)?.name.common}
          />
        </Grid>
      </Grid>
    </ScrollableFilterContent>
  );
}

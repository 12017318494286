import React from 'react';
import { StockLocation } from '../../../../../types/stockLocation';
import { toFilterString } from '../../../../../util/string.util';
import { AddressFilter } from './AddressFilter';
import { BaseFilter, BaseFilterProps } from '../common/BaseFilter';
import { t } from '../../../../../types/translation/Translator';
import { LocationMarkerIcon } from '../../../../icons/LocationMarker/LocationMarkerIcon';
import StockLocationFilterInnerContent from '../../DynamicEntityFilter/InnerContent/StockLocationFilterInnerContent';
import StockLocationFilterDropdownContent from '../../DynamicEntityFilter/Content/StockLocationFilterContent';
import { FilterProps, FilterStorageKey } from './filter.util';
import { TagFilter } from '../Tag/TagFilter';
import { TagRelation } from '../../../../../types/tagRelation';
import { EntityType } from '../../../../../types/comment';

export class StockLocationFilter extends BaseFilter<StockLocation> {
  tagRelations: Map<string, TagRelation>;

  constructor(tagRelations: Map<string, TagRelation>) {
    super(FilterStorageKey.STOCK_LOCATION);
    this.tagRelations = tagRelations;
  }

  filterOptions(): BaseFilter<any>[] {
    return [new TagFilter()];
  }

  toLabel(props: BaseFilterProps): string {
    return t().stockLocation.singular.label;
  }

  toCount(props: BaseFilterProps): number {
    return props.filterProps.stockLocation?.size || 0;
  }

  toIcon(): JSX.Element {
    return <LocationMarkerIcon className={this.className} />;
  }

  toDropdownContent(props: BaseFilterProps): JSX.Element {
    return (
      <StockLocationFilterDropdownContent
        filter={this}
        filterProps={props.filterProps}
        setFilterProps={props.setFilterProps}
      />
    );
  }

  toInnerContent(props: BaseFilterProps): string | JSX.Element {
    return (
      <StockLocationFilterInnerContent
        filter={this}
        filterProps={props.filterProps}
        setFilterProps={props.setFilterProps}
      />
    );
  }

  search(item: StockLocation, query: string) {
    if (
      toFilterString(item.name).includes(query) ||
      toFilterString(item.identifier).includes(query) ||
      (item.address && new AddressFilter().search(item.address, query))
    ) {
      return true;
    }

    return false;
  }

  filteredItems(items: StockLocation[], query: string, filterProps: FilterProps) {
    const allowedStockLocationByTag = new Set<string>();
    if (filterProps.tags?.size) {
      for (const [key, relation] of this.tagRelations) {
        if (filterProps.tags.has(relation.tagId)) {
          if (relation.type === EntityType.stockLocation) allowedStockLocationByTag.add(relation.entityId);
        }
      }
    }

    const result = items.filter(item => {
      if (allowedStockLocationByTag.size && !allowedStockLocationByTag.has(item.id)) {
        return false;
      }

      return this.search(item, query);
    });

    return result;
  }
}

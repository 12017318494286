import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { LoadingSpinner } from '../../../../components/Common/LoadingSpinner';
import { VentoryColor } from '../../../util/color.util';

export interface TagLabelProps {
  color?: string;
  backgroundColor?: string;
  text: string;
  suffix?: JSX.Element;
  dashed?: boolean;
  onClick?: () => Promise<void>;
}

export default function TagLabel({
  backgroundColor = VentoryColor.blue50,
  color = VentoryColor.blue700,
  text,
  suffix,
  dashed = false,
  onClick,
}: TagLabelProps) {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Grid container justifyContent={'end'}>
      <Grid
        style={{ color, backgroundColor, borderColor: color }}
        className={`h-[26px] rounded-5 border select-none ${dashed ? 'border-dashed' : ''} ${
          onClick ? 'cursor-pointer' : ''
        }`}
        display='flex'
        marginY={'auto'}
        justifyContent={'center'}
        item
        px={1}
        onClick={
          onClick
            ? async () => {
                setLoading(true);
                await onClick();
                setLoading(false);
              }
            : () => {}
        }
      >
        <Grid item alignContent={'center'}>
          {!loading ? <p className='font-medium text-sm my-auto'>{text}</p> : <LoadingSpinner color={color} />}
        </Grid>
        {suffix ? (
          <>
            <Grid item className='pl-1' alignContent={'center'} my={'auto'} display={'flex'}>
              {suffix}
            </Grid>
          </>
        ) : null}
      </Grid>
    </Grid>
  );
}

import React, { useContext, useMemo, useState } from 'react';
import { FlexPane } from '../FlexPane/FlexPane';
import SearchBarWithFilter from '../SearchBarWithFilter/SearchBarWithFilter';
import Table, { TableHeader } from '../Table/Table';
import { FileContext } from '../../../../context/FileContext';
import {
  calculateFormattedFileSize,
  CreateFileInput,
  FileEntity,
  FileEntityType,
  FileWithSas,
} from '../../../../types/file';
import { t } from '../../../../types/translation/Translator';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CompanyContext } from '../../../../context/CompanyContext';
import { UserContext } from '../../../../context/UserContext';
import {
  FileMutations,
  CreateFilesResponse,
  CreateFilesVariables,
  FileQueries,
  GetFileDataResponse,
  GetFileDataVariables,
} from '../../../../graphql/file.graphql';
import { DeleteButtonTemplate } from '../Button/Templates/DeleteButton';
import DeleteFilesModal from './Modals/DeleteFilesModal';
import FileViewModal from './Modals/FileViewModal';
import { VentoryColor } from '../../../util/color.util';
import UploadFileInput from './UploadFileInput';
import { FileFilter } from '../../filters/Filter/FileFilter/FileFilter';

interface FilesForEntityPaneProps {
  entityId: string;
  entityType: FileEntityType;
  setError: (error: string) => void;
}

export default function FilesForEntityPane({ entityId, entityType, setError }: FilesForEntityPaneProps) {
  const { currentCompany } = useContext(CompanyContext);
  const { companyUsers } = useContext(UserContext);
  const { fileCache, setFileCache } = useContext(FileContext);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewedFile, setPreviewedFile] = useState<FileEntity | undefined>(undefined);

  const headers: TableHeader<FileEntity>[] = [
    {
      key: 'name',
      name: t().name.singular.label,
      text: (item: FileEntity) => item.name,
    },
    {
      key: 'createdBy',
      name: t().uploadedBy.singular.label,
      text: (item: FileEntity) => companyUsers.get(item.createdBy)?.email || t().unknownUser.singular.label,
    },
    {
      key: 'createdAt',
      name: t().uploadedAt.singular.label,
      text: (item: FileEntity) => new Date(item.createdAt).toLocaleDateString(),
      sortValue: (item: FileEntity) => item.createdAt,
    },
    {
      key: 'type',
      name: t().type.singular.label,
      text: (item: FileEntity) => item.fileType,
    },
    {
      key: 'size',
      name: t().size.singular.label,
      text: (item: FileEntity) => {
        const formattedSize = calculateFormattedFileSize(item.size);
        return `${formattedSize.size.toFixed(0)} ${formattedSize.sizeType}`;
      },
      sortValue: (item: FileEntity) => item.size,
    },
  ];

  const { files, filesLoading, setFiles } = useContext(FileContext);

  const allItems = useMemo(() => {
    return [...files.values()].filter(f => f.entityType === FileEntityType.pmd && f.entityId === entityId);
  }, [files]);

  const [items, setItems] = useState([...files.values()]);
  const [selected, setSelected] = useState(new Set<string>());

  const [create, { loading }] = useMutation<CreateFilesResponse, CreateFilesVariables>(FileMutations.create, {
    onCompleted: result => {
      result.createFiles.forEach(file => files.set(file.id, new FileEntity(file)));
      setFiles(new Map(files));
    },
    onError: error => setError(error.message),
  });
  const [getData] = useLazyQuery<GetFileDataResponse, GetFileDataVariables>(FileQueries.getData);

  const handleFiles = async (files: FileList | null) => {
    if (!files?.length) return setError('Something went wrong uploading your file!');

    for (const file of files) {
      CreateFileInput.fromFile(file, entityId, entityType, currentCompany.id, async result => {
        await create({
          variables: {
            files: [result],
          },
        });
      });
    }
  };

  const handleFilePreview = async (file: FileEntity) => {
    if (file.primaryFileType === 'video' || file.primaryFileType === 'image') {
      setPreviewOpen(true);
      setPreviewedFile(file);
    } else {
      const cachedFileSas = fileCache.get(file.id);
      if (cachedFileSas) return window.open(cachedFileSas.signature);

      setPreviewOpen(false);
      setPreviewedFile(undefined);
      await getData({
        variables: { companyId: file.companyId, entityType: file.entityType, id: file.id },
        onCompleted: response => {
          setFileCache(new Map(fileCache).set(file.id, new FileWithSas(response.fileSas)));
          window.open(response.fileSas.signature);
        },
        onError: error => setError(error.message),
      });
    }
  };

  const buttons = useMemo(() => {
    const shown = [];

    if (selected.size)
      shown.push(
        DeleteButtonTemplate(() => setDeleteModalOpen(true), {
          badge: { backgroundColor: VentoryColor.red700, content: selected.size },
        }),
      );

    return shown;
  }, [selected]);

  return (
    <>
      <DeleteFilesModal
        entityType={FileEntityType.pmd}
        open={deleteModalOpen}
        setOpen={open => {
          setDeleteModalOpen(open);
        }}
        ids={[...selected]}
      />

      {previewedFile ? <FileViewModal open={previewOpen} setOpen={setPreviewOpen} file={previewedFile} /> : null}

      <FlexPane
        header={
          <UploadFileInput
            multiple
            onFile={handleFiles}
            file={undefined}
            loading={loading}
            height='150px'
            subText={t().supportedFilesCanBeImagesVideoOrDocuments.singular.upper}
          />
        }
        content={
          <FlexPane
            header={
              <SearchBarWithFilter
                items={allItems}
                setItems={setItems}
                loading={filesLoading}
                placeholder={t().filterFiles.singular.label}
                buttons={buttons}
                filter={new FileFilter()}
              />
            }
            content={
              <Table
                items={items}
                headers={headers}
                loading={filesLoading}
                onSelected={values => setSelected(values)}
                onClick={handleFilePreview}
              />
            }
          />
        }
      />
    </>
  );
}

import { gql } from '@apollo/client';
import { forPaginated } from './common/paginated.graphql';
import { CreateTriggerInput, DeleteTriggerInput, Trigger, UpdateTriggerInput } from '../types/trigger';

const trigger = gql`
  fragment Trigger on Trigger {
    id
    version
    companyId
    createdAt
    types
    entityType
    entityParentType
    entityValue
    entityIds
    stockLocationAggregateType
    stockLocationIds
    userIds
    customFieldIds
    notifyEntityAssignees
    enabled
  }
`;

const get = gql`
  query Triggers($companyId: String!, $page: Float) {
    triggers(companyId: $companyId, page: $page) {
      data {
        ...Trigger
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${trigger}
`;

export interface GetTriggerVariables {
  companyId: string;
  page?: number;
}

export interface GetTriggerResponse {
  triggers: PaginatedTriggers;
}

class PaginatedTriggers extends forPaginated<Trigger>() {}

const create = gql`
  mutation CreateTrigger($triggers: [CreateTriggerInput!]!) {
    createTrigger(triggers: $triggers) {
      ...Trigger
    }
  }
  ${trigger}
`;

export interface CreateTriggerVariables {
  triggers: CreateTriggerInput[];
}

export interface CreateTriggerResponse {
  createTrigger: Trigger[];
}

const update = gql`
  mutation UpdateTrigger($triggers: [UpdateTriggerInput!]!) {
    updateTrigger(triggers: $triggers) {
      ...Trigger
    }
  }
  ${trigger}
`;

export interface UpdateTriggerVariables {
  triggers: UpdateTriggerInput[];
}

export interface UpdateTriggerResponse {
  updateTrigger: Trigger[];
}

const remove = gql`
  mutation DeleteTrigger($triggers: [DeleteTriggerInput!]!) {
    deleteTrigger(triggers: $triggers) {
      ...Trigger
    }
  }
  ${trigger}
`;

export interface DeleteTriggerVariables {
  triggers: DeleteTriggerInput[];
}

export interface DeleteTriggerResponse {
  deleteTrigger: Trigger[];
}

export const TriggerQueries = {
  get,
};

export const TriggerMutations = {
  create,
  update,
  remove,
};

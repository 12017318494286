import { Task } from '../../../../../types/task';
import { toFilterString } from '../../../../../util/string.util';
import { BaseFilter } from '../common/BaseFilter';
import React from 'react';
import { DateTimeFilter } from '../common/DateTimeFilter';
import { StockLocationFilter } from '../common/StockLocationFilter';
import { t } from '../../../../../types/translation/Translator';
import { CountryFilter } from '../common/CountryFilter';
import { StockLocation } from '../../../../../types/stockLocation';
import { User } from '../../../../../types/user';
import { BinFilter } from '../Bin/BinFilter';
import { BinStatus } from '../../../../../types/binStatus';
import { ProductMasterData } from '../../../../../types/productMasterData';
import { ProductMasterDataFilter } from '../common/ProductMasterDataFilter';
import { TaskStatusFilter } from './TaskStatusFilter';
import { FilterProps, FilterStorageKey } from '../common/filter.util';
import { TaskTypeFilter } from './TaskTypeFilter';
import { EntityType } from '../../../../../types/comment';
import dayjs from 'dayjs';
import { UserFilter } from '../User/UserFilter';
import { TagRelation } from '../../../../../types/tagRelation';
import { TagFilter } from '../Tag/TagFilter';

export class TaskFilter extends BaseFilter<Task> {
  stockLocations: Map<string, StockLocation>;
  users: Map<string, User>;
  binStatuses: Map<string, BinStatus>;
  productMasterData: Map<string, ProductMasterData>;
  tagRelations: Map<string, TagRelation>;

  constructor(
    stockLocations: Map<string, StockLocation>,
    users: Map<string, User>,
    binStatuses: Map<string, BinStatus>,
    productMasterData: Map<string, ProductMasterData>,
    tagRelations: Map<string, TagRelation>,
  ) {
    super(FilterStorageKey.TASK);
    this.stockLocations = stockLocations;
    this.users = users;
    this.binStatuses = binStatuses;
    this.productMasterData = productMasterData;
    this.tagRelations = tagRelations;
  }

  filterOptions = () => [
    new DateTimeFilter(),
    new CountryFilter(),
    new StockLocationFilter(this.tagRelations),
    new UserFilter(this.users),
    new BinFilter(this.binStatuses, this.tagRelations),
    new ProductMasterDataFilter(),
    new TaskStatusFilter(this.stockLocations),
    new TaskTypeFilter(this.stockLocations),
    new TagFilter(),
  ];

  search(item: Task, filter: string) {
    if (
      toFilterString(this.stockLocations.get(item.stockLocationId)?.name).includes(filter) ||
      toFilterString(item.type).includes(filter) ||
      toFilterString(item.status).includes(filter)
    ) {
      return true;
    }
    return false;
  }

  filteredItems(items: Task[], query: string, filterProps: FilterProps): Task[] {
    const allowedStockLocationByTag = new Set<string>();
    if (filterProps.tags?.size) {
      for (const [key, relation] of this.tagRelations) {
        if (filterProps.tags.has(relation.tagId)) {
          if (relation.type === EntityType.stockLocation) allowedStockLocationByTag.add(relation.entityId);
        }
      }
    }

    return items.filter(item => {
      if (filterProps.stockLocation?.size && !filterProps.stockLocation.has(item.stockLocationId)) return false;
      if (filterProps.taskType && filterProps.taskType.size && !filterProps.taskType.has(item.type)) return false;

      if (allowedStockLocationByTag.size && !allowedStockLocationByTag.has(item.stockLocationId)) {
        return false;
      }

      if (
        filterProps.product &&
        filterProps.product.size &&
        !item.identifiers
          .filter(i => i.type === EntityType.pmd)
          .map(i => i.ids)
          .flat()
      ) {
        return false;
      }

      if (filterProps.user && filterProps.user.size && !item.assignedTo.filter(i => filterProps.user?.has(i)).length) {
        return false;
      }

      if (filterProps.country && filterProps.country.size) {
        const stockLocation = this.stockLocations.get(item.stockLocationId);
        if (stockLocation?.address?.countryCode && !filterProps.country.has(stockLocation.address.countryCode))
          return false;
      }
      if (
        filterProps.bin &&
        filterProps.bin.size &&
        !item.identifiers
          .filter(i => i.type === EntityType.bin)
          .map(i => i.ids)
          .flat()
          .filter(i => filterProps.bin?.has(i)).length
      ) {
        return false;
      }

      if (filterProps.taskStatus && filterProps.taskStatus.size) {
        if (!filterProps.taskStatus.has(item.status)) return false;
        if (filterProps.taskStatus.has('Overdue') && !item.overdue) return false;
        if (!filterProps.taskStatus.has('Overdue') && item.overdue) return false;
      }

      if (
        filterProps.timeFrame &&
        (dayjs(filterProps.timeFrame.start).startOf('day').isAfter(dayjs(item.createdAt)) ||
          dayjs(filterProps.timeFrame.end).endOf('day').isBefore(dayjs(item.createdAt)))
      ) {
        return false;
      }

      return this.search(item, query);
    });
  }

  toLabel(): string {
    return t().tasks.singular.label;
  }

  toInnerContent(): string | JSX.Element {
    return <></>;
  }

  toIcon(): React.JSX.Element {
    return <></>;
  }

  toDropdownContent(): React.JSX.Element {
    return <></>;
  }

  toCount(): number {
    return 0;
  }
}

import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forCreate, forDelete, forUpdate } from './common/entity';
import { EntityType } from './comment';

export class TagRelation extends CompanyEntity {
  entityId!: string;
  tagId!: string;
  type!: EntityType;

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    Object.assign(this, cloneDeep(obj));
  }

  override forUpdate(): UpdateTagRelationInput {
    return UpdateTagRelationInput.from(this, UpdateTagRelationInput);
  }

  override forDelete(): DeleteTagRelationInput {
    return DeleteTagRelationInput.from(this, DeleteTagRelationInput);
  }

  override validate(fields: (keyof TagRelation)[]) {
    return this.validateEntity(fields, field => {
      return null;
    });
  }

  withEntityId(entityId: string) {
    this.entityId = entityId;
    return cloneDeep(this);
  }

  withTagId(tagId: string) {
    this.tagId = tagId;
    return cloneDeep(this);
  }

  withType(type: EntityType) {
    this.type = type;
    return cloneDeep(this);
  }
}

export class CreateTagRelationInput extends forCreate(TagRelation) {}
export class UpdateTagRelationInput extends forUpdate(TagRelation) {}
export class DeleteTagRelationInput extends forDelete(TagRelation) {}

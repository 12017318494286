import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forCreate, forDelete, forUpdate } from './common/entity';
import { EntityType } from './comment';

export class Tag extends CompanyEntity {
  name!: string;
  type!: EntityType;
  description?: string;
  color!: string;

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    Object.assign(this, cloneDeep(obj));
  }

  override forUpdate(): UpdateTagInput {
    return UpdateTagInput.from(this, UpdateTagInput);
  }

  override forDelete(): DeleteTagInput {
    return DeleteTagInput.from(this, DeleteTagInput);
  }

  override validate(fields: (keyof Tag)[]) {
    return this.validateEntity(fields, field => {
      return null;
    });
  }

  withName(name: string) {
    this.name = name;
    return cloneDeep(this);
  }

  withType(type: EntityType) {
    this.type = type;
    return cloneDeep(this);
  }

  withDescription(description: string) {
    this.description = description;
    return cloneDeep(this);
  }

  withColor(color: string) {
    this.color = color;
    return cloneDeep(this);
  }
}

export class CreateTagInput extends forCreate(Tag) {}

export class UpdateTagInput extends forUpdate(Tag) {}

export class DeleteTagInput extends forDelete(Tag) {}

import React from 'react';
import { BaseFilter, BaseFilterProps } from '../common/BaseFilter';
import { t } from '../../../../../types/translation/Translator';
import { UserAddIcon } from '../../../../icons/User/UserAddIcon';
import { toFilterString } from '../../../../../util/string.util';
import { CompanyRoleAssignment } from '../../../../../types/companyRoleAssignment';
import { FilterProps } from './filter.util';
import CompanyRoleAssignmentFilterInnerContent from '../../DynamicEntityFilter/InnerContent/CompanyRoleAssignmentFilterInnerContent';
import CompanyRoleAssignmentFilterDropdownContent from '../../DynamicEntityFilter/Content/CompanyRoleAssignmentFilterContent';

export class CompanyRoleAssignmentFilter extends BaseFilter<CompanyRoleAssignment> {
  companyRoles: CompanyRoleAssignment[];
  constructor(companyRoles: CompanyRoleAssignment[]) {
    super();
    this.companyRoles = companyRoles;
  }

  filterOptions = () => [this];

  toLabel(props: BaseFilterProps): string {
    return t().role.plural.label;
  }

  toInnerContent(props: BaseFilterProps): string | JSX.Element {
    return (
      <CompanyRoleAssignmentFilterInnerContent
        filter={this}
        filterProps={props.filterProps}
        setFilterProps={props.setFilterProps}
      />
    );
  }

  toDropdownContent(props: BaseFilterProps): React.JSX.Element {
    return (
      <CompanyRoleAssignmentFilterDropdownContent
        filter={this}
        filterProps={props.filterProps}
        setFilterProps={props.setFilterProps}
      />
    );
  }

  toIcon(props: BaseFilterProps): React.JSX.Element {
    return <UserAddIcon className={this.className} />;
  }

  toCount(props: BaseFilterProps): number {
    return props.filterProps.role?.size || 0;
  }

  search(item: CompanyRoleAssignment, textFilter: string) {
    if (toFilterString(item.email).includes(textFilter) || toFilterString(item.role).includes(textFilter)) {
      return true;
    }
    return false;
  }

  filteredItems(items: CompanyRoleAssignment[], query: string, filterProps: FilterProps): CompanyRoleAssignment[] {
    const result = items.filter(item => {
      if (filterProps.role?.size && !filterProps.role.has(item.role)) return false;
      return this.search(item, query);
    });

    return result;
  }
}

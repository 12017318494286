import { Grid, Popover } from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { Order, OrderType, orderTypeToEntitySubType } from '../../../../../types/order';
import { ProductMasterDataContext } from '../../../../../context/ProductMasterDataContext';
import DropdownSelect from '../../../../Common/DropdownSelect';
import { ProductTransactionSpecifiers } from '../../../../../types/productTransaction';
import { cloneDeep } from '@apollo/client/utilities';
import { TestIdIdentifier, testIds } from '../../../../../util/identifiers/identifiers.util';
import { ProductContext } from '../../../../../context/ProductContext';
import { getSuffix, toQuantityString } from '../../../../../types/unitOfMeasure';
import TextInput from '../../../../Common/TextInput';
import { CustomFieldContext } from '../../../../../context/CustomFieldContext';
import { CustomFieldEntityType, CustomFieldType } from '../../../../../types/customField';
import dayjs from 'dayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import EventIcon from '@mui/icons-material/Event';

import ModalPane from '../../../../../VentoryUI/components/common/Modal/ModalPane';
import { Button } from '../../../../../VentoryUI/components/common/Button/Button';
import DeleteButton from '../../../../../VentoryUI/components/common/Button/Templates/DeleteButton';
import Table, { TableHeader } from '../../../../../VentoryUI/components/common/Table/Table';
import { FlexPane } from '../../../../../VentoryUI/components/common/FlexPane/FlexPane';
import { TagRelationContext } from '../../../../../context/TagRelationContext';

interface CreateOrderProductPaneProps {
  order: Order;
  setOrder: (order: Order) => void;
  next?: () => void;
  back?: () => void;
  add?: () => void;
  cancel?: () => void;
  loading?: boolean;
  setError: (err: string) => void;
  modalHeight: number;
}

export default function CreateOrderProductPane({
  order,
  setOrder,
  next,
  back,
  add,
  cancel,
  setError,
  loading = false,
  modalHeight,
}: CreateOrderProductPaneProps) {
  const { productMasterData, productMasterDataLoading } = useContext(ProductMasterDataContext);
  const { products, productsLoading } = useContext(ProductContext);
  const { customFields } = useContext(CustomFieldContext);
  const { tagRelations } = useContext(TagRelationContext);

  const [selected, setSelected] = useState<Set<string>>(new Set());
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [customFieldDatePickerOpen, setCustomFieldDatePickerOpen] = useState(false);

  const orderProducts = useMemo(() => {
    return [...order.products.values()];
  }, [order]);

  const relevantCustomField = useMemo(() => {
    return [...customFields.values()]
      .filter(
        cf =>
          cf.entityType === CustomFieldEntityType.productTransaction &&
          cf.entitySubtype === orderTypeToEntitySubType(order.type) &&
          cf.type === CustomFieldType.date,
      )
      .at(0);
  }, [customFields]);

  const headers: TableHeader<ProductTransactionSpecifiers>[] = [
    {
      key: 'name',
      name: t().name.singular.label,
      text: (item: ProductTransactionSpecifiers) => productMasterData.get(item.pmdId || '')?.productName || '',
    },
    relevantCustomField
      ? {
          key: relevantCustomField.name,
          name: relevantCustomField.name,
          text: (item: ProductTransactionSpecifiers) => {
            const customFieldValue = item.customFields?.at(0)?.value;
            if (!customFieldValue) return '';
            const date = dayjs(customFieldValue);
            return date.isValid() ? date.toDate().toLocaleDateString() : '';
          },
        }
      : null,
    {
      key: 'quantity',
      name: t().quantity.singular.label,
      text: (item: ProductTransactionSpecifiers) => (
        <Grid container className='h-7' justifyContent={'flex-end'}>
          <Grid item xs={6} marginY={'auto'} justifyContent={'flex-end'} mr={0.1}>
            <TextInput
              onChange={v => {
                const foundItem = order.products.get(item.pmdId || '');
                if (!foundItem) return;
                foundItem.quantity = parseFloat(v) || 0;
                setOrder(cloneDeep(order));
              }}
              value={item.quantity.toString()}
              dynamicUpdate
              testId={new TestIdIdentifier(`quantityInput${item.pmdId}`)}
              heightClass='h-7'
              type='number'
              // Making the suffix a space by default so the text input will display the suffix version even if the UnitOfMeasure is quantity.
              suffix={getSuffix(productMasterData.get(item.pmdId || '')?.unitOfMeasure) || ' '}
            />
          </Grid>
        </Grid>
      ),
      sortValue: (item: ProductTransactionSpecifiers) => item.quantity,
    },
  ].filter(e => e != null) as TableHeader<ProductTransactionSpecifiers>[];

  const quantityForProduct = new Map<string, string>();

  for (const key of [...products.keys()]) {
    const quantity = [...(products.get(key)?.values() || [])]
      .filter(item => {
        if (order.stockLocationId.includes('tag::')) {
          const tagId = order.stockLocationId.replace('tag::', '');
          const relations = new Set(
            [...tagRelations.values()].filter(rel => rel.tagId === tagId).map(rel => rel.entityId),
          );
          return relations.has(item.stockLocationId);
        }
        return item.stockLocationId === order.stockLocationId;
      })
      .reduce((sum, p) => sum + BigInt(p.unitQuantity), BigInt(0));

    if (quantity !== BigInt(0)) {
      quantityForProduct.set(key, toQuantityString(quantity.toString(), productMasterData.get(key)?.unitOfMeasure));
    }
  }

  return (
    <ModalPane
      testId={testIds.createOrderProductPane}
      footer={
        <Grid container>
          <Grid item>
            <Grid container columnSpacing={1}>
              {selected.size ? (
                <Grid item>
                  <DeleteButton
                    loading={loading}
                    onClick={() => {
                      setError('');
                      setOrder(order.removeProducts(selected));
                      setSelected(new Set());
                    }}
                  />
                </Grid>
              ) : null}
              <Grid item>
                {relevantCustomField ? (
                  <Button
                    disabled={!selected.size || loading}
                    onClick={() => {
                      const el = document.getElementById('setCustomField');
                      if (!el) return;
                      setAnchorEl(el);
                      setCustomFieldDatePickerOpen(true);
                    }}
                    id={'setCustomField'}
                    text={relevantCustomField.name}
                    startIcon={<EventIcon />}
                  />
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item flexGrow={1}>
            <Grid container columnSpacing={1} justifyContent={'flex-end'}>
              {back ? (
                <Grid item>
                  <Button onClick={back} testId={testIds.back} text={t().back.singular.label} disabled={loading} />
                </Grid>
              ) : null}
              {next ? (
                <Grid item>
                  <Button
                    style='secondary'
                    loading={loading}
                    disabled={
                      !order.products.size ||
                      (relevantCustomField?.mandatory &&
                        ![...order.products.values()].every(product => {
                          if (!product.customFields?.find(cf => cf.id === relevantCustomField.id)?.value) return false;
                          return true;
                        }))
                    }
                    onClick={next}
                    testId={testIds.next}
                    text={!order.stockLocationId.includes('tag::') ? t().next.singular.label : t().done.singular.label}
                  />
                </Grid>
              ) : null}
              {cancel ? (
                <Grid item>
                  <Button
                    disabled={!order.products.size || loading}
                    onClick={cancel}
                    text={t().cancel.singular.label}
                  />
                </Grid>
              ) : null}
              {add ? (
                <Grid item>
                  <Button
                    style='secondary'
                    loading={loading}
                    disabled={
                      !order.products.size ||
                      (relevantCustomField?.mandatory &&
                        ![...order.products.values()].every(product => {
                          if (!product.customFields?.find(cf => cf.id === relevantCustomField.id)?.value) return false;
                          return true;
                        }))
                    }
                    onClick={add}
                    testId={testIds.add}
                    text={t().add.singular.label}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          {relevantCustomField ? (
            <Popover
              id={'setCustomField'}
              open={customFieldDatePickerOpen}
              anchorEl={anchorEl}
              onClose={() => {
                setCustomFieldDatePickerOpen(false);
                setAnchorEl(null);
              }}
              sx={{ marginTop: '1rem', marginLeft: '0' }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StaticDatePicker
                  displayStaticWrapperAs='desktop'
                  openTo='day'
                  minDate={new Date()}
                  value={undefined}
                  onChange={date => {
                    if (date) {
                      for (const value of selected.values()) {
                        order = order.withProductCustomField(value, relevantCustomField?.toValue(date));
                      }
                    }
                    setOrder(order);
                    setCustomFieldDatePickerOpen(false);
                  }}
                />
              </LocalizationProvider>
            </Popover>
          ) : null}
        </Grid>
      }
    >
      <FlexPane
        spacing={4}
        header={
          <DropdownSelect
            disabled={productMasterDataLoading || productsLoading || loading}
            selectOnly
            testId={testIds.product}
            values={[...productMasterData.values()].filter(
              pmd =>
                !order.products.has(pmd.id) && (order.type === OrderType.inbound || quantityForProduct.get(pmd.id)),
            )}
            selectedValue={null}
            toText={item => `${item?.productName} - ${item.productNumber}` || ''}
            toElement={item => {
              const quantity = quantityForProduct.get(item.id);
              return (
                <Grid container>
                  <Grid item xs={12}>
                    <p className='text-sm font-bold'>{item.productName}</p>
                  </Grid>
                  <Grid item my={'auto'} xs={12}>
                    <p className='text-sm text-gray-500'>{`${item.productNumber}`}</p>
                  </Grid>
                  {order.type === OrderType.outbound ? (
                    <Grid item my={'auto'} xs={12}>
                      <p className={`text-sm text-gray-400`}>{`Available Quantity: ${quantity}`}</p>
                    </Grid>
                  ) : null}
                </Grid>
              );
            }}
            onChange={item => {
              if (!item) return;
              setOrder(
                order.withProduct(item.id, relevantCustomField?.toValue(relevantCustomField.defaultValue ?? '')),
              );
            }}
            placeholder={t().selectAProduct.singular.label}
          />
        }
        content={
          <Table<ProductTransactionSpecifiers>
            modal
            uniqueIdentifier='pmdId'
            loading={productMasterDataLoading || productsLoading || loading}
            onSelected={items => setSelected(new Set(items))}
            selectedValues={selected}
            items={orderProducts}
            headers={headers}
          />
        }
        testId={testIds.createOrderProductPane}
      />
    </ModalPane>
  );
}

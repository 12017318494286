import React from 'react';
import { JSX } from 'react';
import { BinStatus } from '../../../../../types/binStatus';
import { t } from '../../../../../types/translation/Translator';
import BinStatusFilterDropdownContent from '../../DynamicEntityFilter/Content/BinStatusFilterContent';
import BinStatusFilterInnerContent from '../../DynamicEntityFilter/InnerContent/BinStatusFilterInnerContent';
import { BinIcon } from '../../../../icons/Bin/BinIcon';
import { BaseFilter, BaseFilterProps } from '../common/BaseFilter';

export class BinStatusFilter extends BaseFilter<BinStatus> {
  toLabel(): string {
    return t().binStatus.singular.label;
  }

  toCount(): number {
    return 0;
  }

  toIcon(): JSX.Element {
    return <BinIcon />;
  }

  toDropdownContent(props: BaseFilterProps): JSX.Element {
    return (
      <BinStatusFilterDropdownContent
        filter={this}
        filterProps={props.filterProps}
        setFilterProps={props.setFilterProps}
      />
    );
  }

  toInnerContent(props: BaseFilterProps): string | JSX.Element {
    return (
      <BinStatusFilterInnerContent
        filter={this}
        filterProps={props.filterProps}
        setFilterProps={props.setFilterProps}
      />
    );
  }
}

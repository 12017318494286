import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import { t } from '../../../../types/translation/Translator';
import { ContactContext } from '../../../../context/ContactContext';
import { useNavigate } from 'react-router-dom';
import { Contact, ContactType, ShippingLocation } from '../../../../types/contact';
import TextInput from '../../../Common/TextInput';
import UpdateShippingLocationModal from '../Modals/UpdateShippingLocationModal';
import CreateShippingLocationModal from '../Modals/CreateShippingLocationModal';
import { useMutation } from '@apollo/client';
import {
  ContactMutations,
  DeleteContactResponse,
  DeleteContactVariables,
  UpdateContactResponse,
  UpdateContactVariables,
} from '../../../../graphql/contact.graphql';
import { testIds } from '../../../../util/identifiers/identifiers.util';

import BackButton from '../../../../VentoryUI/components/common/Button/Templates/BackButton';
import SaveButton from '../../../../VentoryUI/components/common/Button/Templates/SaveButton';
import DeleteButton from '../../../../VentoryUI/components/common/Button/Templates/DeleteButton';
import { AddButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/AddButton';
import SearchBarWithFilter from '../../../../VentoryUI/components/common/SearchBarWithFilter/SearchBarWithFilter';
import Paper from '../../../../VentoryUI/components/common/Paper/Paper';
import Table from '../../../../VentoryUI/components/common/Table/Table';
import { ShippingLocationFilter } from '../../../../VentoryUI/components/filters/Filter/common/ShippingLocationFilter';
import { FlexPane } from '../../../../VentoryUI/components/common/FlexPane/FlexPane';

interface ContactInfoPaneProps {
  contact?: Contact;
  setError: (error: string) => void;
}

export default function ContactInfoPane({ contact, setError }: ContactInfoPaneProps) {
  const navigate = useNavigate();

  const { contacts, setContacts } = useContext(ContactContext);

  const [contactInput, setContactInput] = useState<Contact>(new Contact(contact));
  const [shippingLocations, setShippingLocations] = useState<ShippingLocation[]>(contactInput.shippingLocations);
  const [selected, setSelected] = useState<number>(-1);
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);

  const [update, { loading: updateLoading }] = useMutation<UpdateContactResponse, UpdateContactVariables>(
    ContactMutations.update,
    {
      onCompleted: res => {
        res.updateContact.forEach(contact => contacts.set(contact.id, new Contact(contact)));
        setContacts(new Map(contacts));
        navigate('/operations/contacts');
      },
      onError: err => setError(err.message),
    },
  );

  const [remove, { loading: deleteLoading }] = useMutation<DeleteContactResponse, DeleteContactVariables>(
    ContactMutations.remove,
    {
      onCompleted: res => {
        res.deleteContact.forEach(contact => contacts.delete(contact.id));
        setContacts(new Map(contacts));
        navigate('/operations/contacts');
      },
    },
  );

  if (!contact) return null;

  const headers = [
    {
      key: 'name',
      name: 'Name',
      text: (item: ShippingLocation) => item.name,
    },
    {
      key: 'email',
      name: 'Contact Email',
      text: (item: ShippingLocation) => item.email || '',
    },
  ];

  const handleUpdate = async () => {
    try {
      await update({
        variables: {
          contacts: [contactInput.forUpdate()],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleDelete = async () => {
    try {
      await remove({
        variables: {
          contacts: [contactInput.forDelete()],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  return (
    <>
      {selected > -1 ? (
        <UpdateShippingLocationModal
          disabled={contactInput.contactType === ContactType.internal}
          open={openUpdateModal}
          setOpen={(v, loc) => {
            if (loc === null) contactInput.shippingLocations.splice(selected, 1);
            if (loc) contactInput.shippingLocations[selected] = loc;
            if (loc === null || loc) setContactInput(new Contact(contactInput));
            setOpenUpdateModal(v);
            setSelected(-1);
          }}
          shippingLocation={contactInput.shippingLocations[selected]}
        />
      ) : null}
      <CreateShippingLocationModal
        open={openCreateModal}
        setOpen={(v, loc) => {
          if (loc) contactInput.shippingLocations.push(loc);
          setOpenCreateModal(v);
        }}
      />

      <FlexPane
        testId={testIds.contactInfoPane}
        header={
          <Paper>
            <Grid container columnSpacing={1} rowSpacing={1}>
              <Grid item xs={6}>
                <TextInput
                  mandatory
                  disabled={contactInput.contactType === ContactType.internal}
                  value={contactInput.name}
                  label={t().name.singular.label}
                  placeholder={t().name.singular.label}
                  onChange={v => contactInput.withName(v)}
                  testId={testIds.name}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  disabled={contactInput.contactType === ContactType.internal}
                  value={contactInput.email}
                  label={t().contactEmail.singular.label}
                  placeholder={t().contactEmail.singular.label}
                  onChange={v => contactInput.withEmail(v)}
                  testId={testIds.contactEmail}
                />
              </Grid>
            </Grid>
          </Paper>
        }
        content={
          <FlexPane
            header={
              <SearchBarWithFilter
                items={contactInput.shippingLocations}
                loading={deleteLoading || updateLoading}
                setItems={setShippingLocations}
                testId={testIds.shippingLocation}
                placeholder={t().filterShippingLocations.singular.label}
                filter={new ShippingLocationFilter()}
                buttons={
                  contactInput.contactType === ContactType.internal
                    ? []
                    : [AddButtonTemplate(() => setOpenCreateModal(true))]
                }
              />
            }
            content={
              <Table
                items={shippingLocations}
                onClick={item => {
                  setSelected(contactInput.shippingLocations.findIndex(sl => sl === item));
                  setOpenUpdateModal(true);
                }}
                headers={headers}
              />
            }
          />
        }
        footer={
          <Grid container>
            <Grid item>
              {contactInput.contactType === ContactType.internal ? null : (
                <Grid item>
                  <DeleteButton loading={deleteLoading} disabled={updateLoading} onClick={handleDelete} />
                </Grid>
              )}
            </Grid>
            <Grid item flexGrow={1}>
              <Grid container columnSpacing={1} justifyContent={'flex-end'}>
                <Grid item>
                  <BackButton
                    disabled={updateLoading || deleteLoading}
                    onClick={() => navigate('/operations/contacts')}
                    style={contactInput.contactType === ContactType.internal ? 'secondary' : 'primary'}
                  />
                </Grid>
                {contactInput.contactType === ContactType.internal ? null : (
                  <Grid item>
                    <SaveButton disabled={deleteLoading} loading={updateLoading} onClick={handleUpdate} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </>
  );
}

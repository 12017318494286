import React, { useContext, useMemo, useState } from 'react';
import { t } from '../../../types/translation/Translator';
import CreateTriggerModal from './Modals/CreateTriggerModal';
import { TriggerContext } from '../../../context/TriggerContext';
import { Trigger, getTextForTrigger } from '../../../types/trigger';
import { ProductMasterDataContext } from '../../../context/ProductMasterDataContext';
import { StockLocationContext } from '../../../context/StockLocationContext';
import DeleteTriggerModal from './Modals/DeleteTriggerModal';
import { LotContext } from '../../../context/LotContext';
import { testIds } from '../../../util/identifiers/identifiers.util';
import { CustomFieldContext } from '../../../context/CustomFieldContext';
import { CompanyContext } from '../../../context/CompanyContext';
import SearchBarWithFilter from '../../../VentoryUI/components/common/SearchBarWithFilter/SearchBarWithFilter';
import { DeleteButtonTemplate } from '../../../VentoryUI/components/common/Button/Templates/DeleteButton';
import { AddButtonTemplate } from '../../../VentoryUI/components/common/Button/Templates/AddButton';
import Table, { TableHeader } from '../../../VentoryUI/components/common/Table/Table';
import { FlexPane } from '../../../VentoryUI/components/common/FlexPane/FlexPane';
import { TriggerFilter } from '../../../VentoryUI/components/filters/Filter/common/TriggerFilter';
import { UserContext } from '../../../context/UserContext';
import UpdateTriggerModal from './Modals/UpdateTriggerModal';
import StatusLabel from '../../../VentoryUI/components/common/StatusLabel/StatusLabel';
import { VentoryColor } from '../../../VentoryUI/util/color.util';
import { useMutation } from '@apollo/client';
import { TriggerMutations, UpdateTriggerResponse, UpdateTriggerVariables } from '../../../graphql/trigger.graphql';
import { StockLocationRoleAssignmentContext } from '../../../context/StockLocationRoleAssignmentContext';
import { CompanyRoleAssignmentContext } from '../../../context/CompanyRoleAssignmentContext';
import { CompanyRole } from '../../../types/companyRoleAssignment';
import { StockLocationRole } from '../../../types/stockLocationRoleAssignment';

export default function TriggersSettingsPane() {
  const { currentCompany } = useContext(CompanyContext);
  const { triggers, triggersLoading, setTriggers } = useContext(TriggerContext);
  const { productMasterData } = useContext(ProductMasterDataContext);
  const { stockLocations } = useContext(StockLocationContext);
  const { lots } = useContext(LotContext);
  const { customFields } = useContext(CustomFieldContext);
  const { companyUsers } = useContext(UserContext);
  const { filteredStockLocations } = useContext(StockLocationContext);
  const { hasStockLocationRole } = useContext(StockLocationRoleAssignmentContext);
  const { currentCompanyRole } = useContext(CompanyRoleAssignmentContext);

  const [filter, setFilter] = useState<string>('');
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);

  const [selectedTrigger, setSelectedTrigger] = useState<Trigger | null>(null);

  const [selected, setSelected] = useState<Set<string>>(new Set());

  const defaultTriggers = [
    Trigger.defaultLotTrigger(currentCompany.id),
    Trigger.defaultPmdTrigger(currentCompany.id),
    Trigger.defaultTaskTrigger(currentCompany.id),
  ];
  const allTriggers = useMemo(() => {
    return [
      ...[...triggers.values()].filter(
        trigger =>
          trigger.stockLocationIds.filter(
            sl =>
              currentCompanyRole(currentCompany.id) === CompanyRole.administrator ||
              (filteredStockLocations.has(sl) &&
                hasStockLocationRole(currentCompany.id, sl, StockLocationRole.STOCK_LOCATION_MANAGER)),
          ).length,
      ),
      ...(currentCompanyRole(currentCompany.id) === CompanyRole.administrator ? defaultTriggers : []),
    ];
  }, [triggers]);

  const [items, setItems] = useState(allTriggers);

  const triggerTexts = useMemo(() => {
    const texts = new Map();

    for (const [key, value] of triggers) {
      texts.set(key, getTextForTrigger(value, productMasterData, stockLocations, lots, customFields, companyUsers));
    }

    for (const trigger of defaultTriggers) {
      texts.set(
        trigger.id,
        getTextForTrigger(trigger, productMasterData, stockLocations, lots, customFields, companyUsers),
      );
    }

    return texts;
  }, [triggers]);

  const headers: TableHeader<Trigger>[] = [
    {
      key: 'trigger',
      name: 'Trigger',
      text: (item: Trigger) => triggerTexts.get(item.id) || 'Unknown Trigger',
      weight: 10,
      position: 'start',
    },
    {
      key: 'status',
      name: t().status.singular.label,
      sortValue: item => item.enabled,
      weight: 2,
      text: item => (
        <StatusLabel
          color={
            item.enabled
              ? {
                  color: VentoryColor.green700,
                  backgroundColor: VentoryColor.green50,
                }
              : {
                  color: VentoryColor.grey700,
                  backgroundColor: VentoryColor.grey200,
                }
          }
          text={item.enabled ? t().enabled.singular.label : t().disabled.plural.label}
        />
      ),
    },
  ];

  const [update, { loading }] = useMutation<UpdateTriggerResponse, UpdateTriggerVariables>(TriggerMutations.update, {
    onCompleted: res => {
      res.updateTrigger.forEach(trigger => triggers.set(trigger.id, new Trigger(trigger)));
      setTriggers(new Map(triggers));
    },
  });

  const handleUpdate = async () => {
    const input = [...triggers.values()]
      .filter(trigger => selected.has(trigger.id))
      .map(trigger => {
        trigger.enabled = false;
        return trigger;
      });

    try {
      await update({
        variables: {
          triggers: input,
        },
      });
    } catch (e) {}
  };

  const buttons = useMemo(() => {
    const shown = [];

    if (selected.size) {
      shown.push({
        testId: testIds.delete,
        text: t().disable.singular.label,
        onClick: handleUpdate,
      });
      shown.push(DeleteButtonTemplate(() => setOpenDeleteModal(true)));
    }
    shown.push(AddButtonTemplate(() => setOpenCreateModal(true)));

    return shown;
  }, [selected]);

  return (
    <>
      <CreateTriggerModal open={openCreateModal} setOpen={setOpenCreateModal} />
      <DeleteTriggerModal open={openDeleteModal} setOpen={setOpenDeleteModal} idsToDelete={selected} />
      <UpdateTriggerModal
        open={openUpdateModal}
        setOpen={setOpenUpdateModal}
        onClose={() => {
          setSelectedTrigger(null);
          setOpenUpdateModal(false);
        }}
        trigger={selectedTrigger}
      />
      <FlexPane
        testId={testIds.triggersSettingsPane}
        header={
          <SearchBarWithFilter
            placeholder={t().filterTriggers.singular.label}
            testId={testIds.triggers}
            items={allTriggers}
            buttons={buttons}
            setItems={setItems}
            filter={new TriggerFilter(triggerTexts)}
          />
        }
        content={
          <Table
            loading={triggersLoading}
            items={items}
            headers={headers}
            onSelected={items => setSelected(new Set(items))}
            disabled={item => item.id.length === 1}
            onClick={item => {
              setOpenUpdateModal(true);
              setSelectedTrigger(item);
            }}
          />
        }
      />
    </>
  );
}

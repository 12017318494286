import React, { useContext, useMemo, useState } from 'react';
import { AlertContext } from '../../context/AlertContext';
import { Alert } from '../../types/alert';
import { Grid } from '@mui/material';
import { testIds } from '../../util/identifiers/identifiers.util';
import { TriggerEntityType } from '../../types/trigger';
import { StockLocationAggregateType } from '../../types/common/stockLocationAggregateType';
import { t } from '../../types/translation/Translator';
import { useMutation } from '@apollo/client';
import { AlertMutations, ProcessAlertResponse, ProcessAlertVariables } from '../../graphql/alert.graphql';
import { StockLocationRoleAssignmentContext } from '../../context/StockLocationRoleAssignmentContext';
import { StockLocationRole } from '../../types/stockLocationRoleAssignment';
import Table, { TableHeader } from '../../VentoryUI/components/common/Table/Table';
import Pane from '../../VentoryUI/components/common/Pane/Pane';
import { FlexPane } from '../../VentoryUI/components/common/FlexPane/FlexPane';
import SearchBarWithFilter from '../../VentoryUI/components/common/SearchBarWithFilter/SearchBarWithFilter';
import { AlertFilter } from '../../VentoryUI/components/filters/Filter/Alert/AlertFilter';
import { ProductMasterDataContext } from '../../context/ProductMasterDataContext';
import { BinStatusContext } from '../../context/BinStatusContext';
import { TagRelationContext } from '../../context/TagRelationContext';

export default function AlertScreen() {
  const { alerts, alertsLoading, setAlerts } = useContext(AlertContext);
  const { hasStockLocationRole } = useContext(StockLocationRoleAssignmentContext);
  const { productMasterData, productMasterDataLoading } = useContext(ProductMasterDataContext);
  const { binStatuses } = useContext(BinStatusContext);
  const { tagRelations } = useContext(TagRelationContext);

  const allItems = useMemo(() => {
    return [...alerts.values()];
  }, [alerts]);

  const [items, setItems] = useState([...alerts.values()]);
  const [error, setError] = useState<string>('');

  const [processAlert, { loading }] = useMutation<ProcessAlertResponse, ProcessAlertVariables>(AlertMutations.process, {
    onCompleted: response => {
      alerts.set(response.processAlert.id, new Alert(response.processAlert));
      setAlerts(new Map(alerts));
    },
    onError: error => {
      setError(error.message);
    },
  });

  async function handleProcess(alert: Alert) {
    await processAlert({ variables: { alertId: alert.id, companyId: alert.companyId } });
  }

  const headers: TableHeader<Alert>[] = [
    {
      key: 'alertTime',
      name: t().dateAndTime.singular.label,
      text: (item: Alert) =>
        `${new Date(item.createdAt).toLocaleDateString()} - ${new Date(item.createdAt).toLocaleTimeString()}`,
    },
    {
      key: 'alert',
      name: t().alert.plural.label,
      text: (item: Alert) => item.alertString ?? '',
      weight: 4,
    },
    {
      key: 'process',
      name: '',
      text: (item: Alert) => {
        if (
          !item.processed &&
          item.entityType === TriggerEntityType.pmd &&
          item.entityValue.includes('${REORDER_RULE}') &&
          hasStockLocationRole(item.companyId, item.stockLocationIds[0], StockLocationRole.STOCK_LOCATION_MANAGER) &&
          (item.stockLocationAggregateType === StockLocationAggregateType.any ||
            item.stockLocationAggregateType === StockLocationAggregateType.anyOf)
        ) {
          return (
            <Grid item xs={12} display={'flex'} justifyContent={'end'}>
              <p className='text-blue-500 font-semibold h-7 py-1 text-sm' onClick={() => handleProcess(item)}>
                {t().createOrder.singular.label}
              </p>
            </Grid>
          );
        }

        return '';
      },
    },
  ];

  return (
    <Pane testId={testIds.alertScreen} error={error}>
      <FlexPane
        header={
          <SearchBarWithFilter
            loading={productMasterDataLoading}
            items={allItems}
            setItems={setItems}
            placeholder={t().filterAlerts.singular.label}
            filter={new AlertFilter(productMasterData, binStatuses, tagRelations)}
          />
        }
        content={
          <Table
            title={t().alert.plural.label}
            loading={alertsLoading}
            items={items}
            headers={headers}
            totalItemCount={allItems.length}
          />
        }
      />
    </Pane>
  );
}

import React, { useEffect, useState } from 'react';
import { FilterProps } from '../../Filter/common/filter.util';
import { Divider, Grid } from '@mui/material';
import { DeleteIcon } from '../../../../icons/Delete/DeleteIcon';
import { VentoryColor } from '../../../../util/color.util';
import { t } from '../../../../../types/translation/Translator';
import MultiSelectionList from '../../../common/MultiSelectionList/MultiSelectionList';
import SearchBar from '../../../common/SearchBar/SearchBar';
import ScrollableFilterContent from './ScrollableFilterContent';
import { CompanyRoleAssignmentFilter } from '../../Filter/common/CompanyRoleAssignmentFilter';
import { companyRoleToString } from '../../../../../types/companyRoleAssignment';

interface CompanyRoleAssignmentFilterDropdownContentProps {
  filter: CompanyRoleAssignmentFilter;
  filterProps: FilterProps;
  setFilterProps: (filter: FilterProps) => void;
}

export default function CompanyRoleAssignmentFilterDropdownContent({
  filter,
  filterProps,
  setFilterProps,
}: CompanyRoleAssignmentFilterDropdownContentProps) {
  const [query, setQuery] = useState<string>('');

  const filterRoles = (): Set<string> => {
    return new Set(filter.filteredItems([...filter.companyRoles], query, filterProps).map(role => role.role));
  };
  const [values, setValues] = useState<Set<string>>(filterRoles());

  const handleChange = (selected: Set<string>) => {
    filterProps.role = selected;
    if (!filterProps.role?.size) filterProps.role = undefined;
    setFilterProps({ ...filterProps });
  };

  const handleRemove = () => {
    filterProps.role = undefined;
    setFilterProps({ ...filterProps });
  };

  useEffect(() => {
    setValues(filterRoles());
  }, [query]);

  return (
    <ScrollableFilterContent>
      <Grid container>
        <Grid item xs={12} className='flex py-2 px-3'>
          <Grid container>
            <Grid item>
              <p className='text-[13px] font-[500] text-ventory-grey-500'>{t().role.singular.label}</p>
            </Grid>
            <Grid item flexGrow={1} display={'flex'} justifyContent={'flex-end'} sx={{ color: VentoryColor.deleteRed }}>
              <Grid item onClick={handleRemove} className='cursor-pointer'>
                <DeleteIcon />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SearchBar onChange={i => setQuery(i)} className='pl-3 pr-4' />
        </Grid>
        <Grid item className='py-3' xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <MultiSelectionList
            selected={filterProps.role || new Set()}
            values={values}
            onChange={handleChange}
            toText={id => companyRoleToString(filter.companyRoles.find(cr => cr.role === id)?.role || '')}
          />
        </Grid>
      </Grid>
    </ScrollableFilterContent>
  );
}

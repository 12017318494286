import { gql } from '@apollo/client';
import { IdArray } from '../types/common/entity';
import { forPaginated } from './common/paginated.graphql';
import {
  CreateTagRelationInput,
  DeleteTagRelationInput,
  TagRelation,
  UpdateTagRelationInput,
} from '../types/tagRelation';

const tagRelation = gql`
  fragment TagRelation on TagRelation {
    id
    version
    companyId
    type
    entityId
    tagId
  }
`;

const get = gql`
  query TagRelations($companyId: String!, $page: Float) {
    tagRelations(companyId: $companyId, page: $page) {
      data {
        ...TagRelation
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${tagRelation}
`;

export interface GetTagRelationsVariables {
  companyId: string;
  page?: number;
}

export interface GetTagRelationsResponse {
  tagRelations: PaginatedTagRelations;
}

class PaginatedTagRelations extends forPaginated<TagRelation>() {}

const create = gql`
  mutation CreateTagRelation($tagRelations: [CreateTagRelationInput!]!) {
    createTagRelation(tagRelations: $tagRelations) {
      ...TagRelation
    }
  }
  ${tagRelation}
`;

export interface CreateTagRelationVariables {
  tagRelations: CreateTagRelationInput[];
}

export interface CreateTagRelationResponse {
  createTagRelation: TagRelation[];
}

const update = gql`
  mutation UpdateTagRelation($tagRelations: [UpdateTagRelationInput!]!) {
    updateTagRelation(tagRelations: $tagRelations) {
      ...TagRelation
    }
  }
  ${tagRelation}
`;

export interface UpdateTagRelationVariables {
  tagRelations: UpdateTagRelationInput[];
}

export interface UpdateTagRelationResponse {
  updateTagRelation: TagRelation[];
}

const remove = gql`
  mutation DeleteTagRelation($tagRelations: [DeleteTagRelationInput!]!) {
    deleteTagRelation(tagRelations: $tagRelations) {
      ...TagRelation
    }
  }
  ${tagRelation}
`;

export interface DeleteTagRelationVariables {
  tagRelations: DeleteTagRelationInput[];
}

export interface DeleteTagRelationResponse {
  deleteTagRelation: IdArray;
}

export const TagRelationQueries = {
  get,
};

export const TagRelationMutations = {
  create,
  update,
  remove,
};

export const TagRelationFragment = {
  tagRelation,
};

import { Divider, Grid } from '@mui/material';
import { StockLocation } from '../../../../types/stockLocation';
import TextInput from '../../../Common/TextInput';
import React, { useContext, useMemo, useState } from 'react';
import { t } from '../../../../types/translation/Translator';
import Checkbox from '../../../../VentoryUI/components/common/Checkbox/Checkbox';
import DropdownSelect from '../../../Common/DropdownSelect';
import { countries } from '../../../../util/country';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import { FlexPane } from '../../../../VentoryUI/components/common/FlexPane/FlexPane';
import Paper from '../../../../VentoryUI/components/common/Paper/Paper';
import AddTagLabel from '../../../../VentoryUI/components/common/TagLabel/Templates/AddTagLabel';
import AddStockLocationTagModal from '../Common/AddStockLocationTagModal';
import CloseTagLabel from '../../../../VentoryUI/components/common/TagLabel/Templates/CloseTagLabel';
import { TagRelationContext } from '../../../../context/TagRelationContext';
import { TagContext } from '../../../../context/TagContext';
import { EntityType } from '../../../../types/comment';
import { TagRelation } from '../../../../types/tagRelation';
import {
  DeleteTagRelationResponse,
  DeleteTagRelationVariables,
  TagRelationMutations,
} from '../../../../graphql/tagRelation.graphql';
import { useMutation } from '@apollo/client';
import ErrorBox from '../../../Common/ErrorBox';

interface StockLocationInfoInputProps {
  stockLocation: StockLocation;
  setStockLocation: (stockLocation: StockLocation) => void;
  footer: (input: StockLocation) => JSX.Element;
  title?: string;
}

export default function StockLocationInfoPane({
  stockLocation,
  footer,
  title,
  setStockLocation,
}: StockLocationInfoInputProps) {
  const { tags } = useContext(TagContext);
  const { tagRelations, setTagRelations } = useContext(TagRelationContext);

  const [openTagModal, setOpenTagModal] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const [remove, { loading }] = useMutation<DeleteTagRelationResponse, DeleteTagRelationVariables>(
    TagRelationMutations.remove,
    {
      onCompleted: res => {
        const rel = res.deleteTagRelation[0];
        tagRelations.delete(rel.id);
        setTagRelations(new Map(tagRelations));
      },
      onError: res => setError(res.message),
    },
  );

  const relations = useMemo(() => {
    return [...tagRelations.values()].filter(
      relation => relation.type === EntityType.stockLocation && relation.entityId === stockLocation.id,
    );
  }, [tagRelations]);

  const handleDelete = async (rel: TagRelation) => {
    try {
      await remove({
        variables: {
          tagRelations: [rel.forDelete()],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  return (
    <>
      <AddStockLocationTagModal
        open={openTagModal}
        setOpen={() => setOpenTagModal(false)}
        stockLocation={stockLocation}
      />
      <FlexPane
        testId={testIds.stockLocationInfoPane}
        content={
          <Paper>
            <ErrorBox error={error} />
            <Grid container rowSpacing={2} columnSpacing={2}>
              {title ? (
                <Grid item xs={12}>
                  <p className='font-semibold text-xl'>{title}</p>
                </Grid>
              ) : null}
              <Grid item xs={6}>
                <Grid container rowSpacing={1}>
                  <Grid item xs={12}>
                    <TextInput
                      mandatory
                      value={stockLocation.name}
                      label={t().stockLocationName.singular.label}
                      placeholder={t().stockLocationName.singular.label}
                      onChange={v => setStockLocation(stockLocation.withName(v))}
                      testId={testIds.name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      value={stockLocation.identifier}
                      label={t().stockLocationIdentifier.singular.label}
                      placeholder={t().stockLocationIdentifier.singular.label}
                      onChange={v => setStockLocation(stockLocation.withIdentifier(v))}
                      testId={testIds.identifier}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Checkbox
                      label={t().mobileStockLocation.singular.label}
                      onChange={v => setStockLocation(stockLocation.withMobile(v))}
                      value={stockLocation.mobile || false}
                      testId={testIds.mobile}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container rowSpacing={1} columnSpacing={1}>
                  <Grid item xs={12}>
                    <TextInput
                      value={stockLocation.address?.addressLine1}
                      label={t().addressLine1.singular.label}
                      placeholder={t().addressLine1.singular.label}
                      onChange={v => setStockLocation(stockLocation.withAddressLine1(v))}
                      testId={testIds.addressLine1}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      value={stockLocation.address?.addressLine2}
                      label={t().addressLine2.singular.label}
                      placeholder={t().addressLine2.singular.label}
                      onChange={v => setStockLocation(stockLocation.withAddressLine2(v))}
                      testId={testIds.addressLine2}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      value={stockLocation.address?.postalCode}
                      label={t().postalCode.singular.label}
                      placeholder={t().postalCode.singular.label}
                      onChange={v => setStockLocation(stockLocation.withPostalCode(v))}
                      testId={testIds.postalCode}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      value={stockLocation.address?.city}
                      label={t().city.singular.label}
                      placeholder={t().city.singular.label}
                      onChange={v => setStockLocation(stockLocation.withCity(v))}
                      testId={testIds.city}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      value={stockLocation.address?.region}
                      label={t().region.singular.label}
                      placeholder={t().region.singular.label}
                      onChange={v => setStockLocation(stockLocation.withRegion(v))}
                      testId={testIds.region}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DropdownSelect
                      testId={testIds.country}
                      placeholder={t().country.singular.label}
                      label={t().country.singular.label}
                      selectedValue={countries.find(c => c.cca2 === stockLocation.address?.countryCode) || null}
                      maxHeight='150px'
                      values={countries}
                      toText={(item: any) => item.name.common}
                      onChange={(item: any) =>
                        setStockLocation(stockLocation.withCountryCode(item ? item.cca2 : undefined))
                      }
                    />
                  </Grid>
                  <Grid item my={1} xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item flexGrow={1}>
                        <p className='text-sm font-medium text-slate-800'>{t().tag.plural.label}</p>
                      </Grid>
                      <Grid item xs={12} mt={1}>
                        <Grid container>
                          {relations.map(rel => (
                            <Grid item mr={0.5} key={rel.id}>
                              <CloseTagLabel
                                onClick={async () => {
                                  await handleDelete(rel);
                                }}
                                text={tags.get(rel.tagId)?.name || t().unknown.singular.label}
                              />
                            </Grid>
                          ))}
                          <Grid item>
                            <AddTagLabel
                              onClick={async () => {
                                setOpenTagModal(true);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        }
        footer={footer(stockLocation)}
      />
    </>
  );
}

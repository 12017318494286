import React from 'react';
import { useContext } from 'react';
import { t } from '../../../../../types/translation/Translator';
import InnerFilterContent, { FilterInnerContentProps } from './DynamicFilterInnerContent';
import { Tag } from '../../../../../types/tag';
import { TagContext } from '../../../../../context/TagContext';

export default function TagFilterInnerContent({ filter, filterProps, setFilterProps }: FilterInnerContentProps<Tag>) {
  const { tags } = useContext(TagContext);

  return (
    <InnerFilterContent
      filterProps={{ filterProps, setFilterProps }}
      filter={filter}
      items={[...(filterProps.tags || [])]}
      text={item => tags.get(item)?.name || t().unknownTag.singular.label}
      onRemove={i => {
        filterProps.tags?.delete(i);
        if (!filterProps.tags?.size) filterProps.tags = undefined;
        setFilterProps({ ...filterProps });
      }}
    />
  );
}

import React, { useContext, useState } from 'react';
import { CompanyContext } from '../../../../../context/CompanyContext';
import { TagContext } from '../../../../../context/TagContext';
import {
  DeleteTagResponse,
  DeleteTagVariables,
  TagMutations,
  UpdateTagResponse,
  UpdateTagVariables,
} from '../../../../../graphql/tag.graphql';
import { Tag } from '../../../../../types/tag';
import { useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import CancelButton from '../../../../../VentoryUI/components/common/Button/Templates/CancelButton';
import Modal from '../../../../../VentoryUI/components/common/Modal/Modal';
import { t } from '../../../../../types/translation/Translator';
import DeleteButton from '../../../../../VentoryUI/components/common/Button/Templates/DeleteButton';
import SaveButton from '../../../../../VentoryUI/components/common/Button/Templates/SaveButton';
import TagItem from '../../Common/Tag/TagItem';

interface UpdateTagModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  tag: Tag;
}

export default function UpdateTagModal({ open, setOpen, tag }: UpdateTagModalProps) {
  const { currentCompany } = useContext(CompanyContext);
  const { tags, setTags } = useContext(TagContext);

  const [error, setError] = useState<string>('');

  const [update, { loading }] = useMutation<UpdateTagResponse, UpdateTagVariables>(TagMutations.update, {
    onCompleted: res => {
      const tag = res.updateTag[0];
      tags.set(tag.id, new Tag(tag));
      setTags(new Map(tags));
      handleClose();
    },
    onError: res => setError(res.message),
  });

  const [remove, { loading: removeLoading }] = useMutation<DeleteTagResponse, DeleteTagVariables>(TagMutations.remove, {
    onCompleted: res => {
      const tag = res.deleteTag[0];
      tags.delete(tag.id);
      setTags(new Map(tags));
      handleClose();
    },
  });

  const handleClose = () => {
    setOpen(false);
    setError('');
  };

  const handleUpdate = async (tag: Tag) => {
    try {
      await update({
        variables: {
          tags: [tag],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleDelete = async (tag: Tag) => {
    try {
      await remove({
        variables: {
          tags: [tag.forDelete()],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const footer = (tag: Tag) => (
    <Grid container columnSpacing={1} justifyContent={'space-between'}>
      <Grid item>
        <DeleteButton disabled={loading} loading={removeLoading} onClick={() => handleDelete(tag)} />
      </Grid>
      <Grid item>
        <Grid container columnSpacing={1}>
          <Grid item>
            <CancelButton disabled={loading || removeLoading} onClick={handleClose} />
          </Grid>
          <Grid item>
            <SaveButton loading={loading} disabled={removeLoading} onClick={() => handleUpdate(tag)} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Modal
      open={open}
      height='250px'
      width='60%'
      onClose={handleClose}
      title={t().updateTag.singular.label}
      error={error}
    >
      <TagItem setError={setError} tag={tag} footer={footer} />
    </Modal>
  );
}

import React from 'react';
import { JSX } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { ListIcon } from '../../../../icons/List/ListIcon';
import OrderStatusFilterContent from '../../DynamicEntityFilter/Content/OrderStatusFilterContent';
import OrderStatusFilterInnerContent from '../../DynamicEntityFilter/InnerContent/OrderStatusFilterInnerContent';
import { StockLocation } from '../../../../../types/stockLocation';
import { OrderTableSettings } from '../../../../../types/orderTableSettings';
import { BaseFilter, BaseFilterProps } from '../common/BaseFilter';

export class OrderStatusFilter extends BaseFilter<string> {
  filteredStockLocations: Map<string, StockLocation>;
  tableSettings: Map<string, OrderTableSettings>;

  constructor(filteredStockLocations: Map<string, StockLocation>, tableSettings: Map<string, OrderTableSettings>) {
    super();
    this.filteredStockLocations = filteredStockLocations;
    this.tableSettings = tableSettings;
  }

  toLabel(): string {
    return t().orderStatus.singular.label;
  }

  toCount(props: BaseFilterProps): number {
    return props.filterProps.orderStatus?.size || 0;
  }

  toIcon(): JSX.Element {
    return <ListIcon className={this.className} />;
  }

  toDropdownContent(props: BaseFilterProps): JSX.Element {
    return (
      <OrderStatusFilterContent
        filter={this}
        filterProps={props.filterProps}
        setFilterProps={props.setFilterProps}
        color=''
      />
    );
  }

  toInnerContent(props: BaseFilterProps): string | JSX.Element {
    return (
      <OrderStatusFilterInnerContent
        filter={this}
        filterProps={props.filterProps}
        setFilterProps={props.setFilterProps}
      />
    );
  }
}

import React, { useContext, useEffect, useState } from 'react';
import { FilterProps } from '../../Filter/common/filter.util';
import { Divider, Grid } from '@mui/material';
import { DeleteIcon } from '../../../../icons/Delete/DeleteIcon';
import { VentoryColor } from '../../../../util/color.util';
import { t } from '../../../../../types/translation/Translator';
import { StockLocationContext } from '../../../../../context/StockLocationContext';
import MultiSelectionList from '../../../common/MultiSelectionList/MultiSelectionList';
import SearchBar from '../../../common/SearchBar/SearchBar';
import { BinContext } from '../../../../../context/BinContext';
import ScrollableFilterContent from './ScrollableFilterContent';
import { BinFilter } from '../../Filter/Bin/BinFilter';

interface BinFilterDropdownContentProps {
  filter: BinFilter;
  filterProps: FilterProps;
  setFilterProps: (filter: FilterProps) => void;
}

export default function BinFilterDropdownContent({
  filter,
  filterProps,
  setFilterProps,
}: BinFilterDropdownContentProps) {
  const { stockLocations } = useContext(StockLocationContext);
  const { bins } = useContext(BinContext);

  const [query, setQuery] = useState<string>('');

  const filterBins = () => {
    return new Set(filter.filteredItems([...bins.values()], query, filterProps).map(bin => bin.id));
  };

  const [values, setValues] = useState<Set<string>>(filterBins());

  const handleChange = (selected: Set<string>) => {
    filterProps.bin = selected;
    if (!filterProps.bin?.size) filterProps.bin = undefined;
    setFilterProps({ ...filterProps });
  };

  const handleRemove = () => {
    filterProps.bin = undefined;
    setFilterProps({ ...filterProps });
  };

  useEffect(() => {
    setValues(filterBins());
  }, [query]);

  return (
    <ScrollableFilterContent>
      <Grid container>
        <Grid item xs={12} className='flex py-2 px-3'>
          <Grid container>
            <Grid item>
              <p className='text-[13px] font-[500] text-ventory-grey-500'>{t().bin.singular.label}</p>
            </Grid>
            <Grid item flexGrow={1} display={'flex'} justifyContent={'flex-end'} sx={{ color: VentoryColor.deleteRed }}>
              <Grid item onClick={handleRemove} className='cursor-pointer'>
                <DeleteIcon />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SearchBar onChange={i => setQuery(i)} className='pl-3 pr-4' />
        </Grid>
        <Grid item className='py-3' xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <MultiSelectionList
            selected={filterProps.bin}
            values={values}
            onChange={handleChange}
            toText={id => `${bins.get(id)?.name}`}
            toSubText={id => `${stockLocations.get(bins.get(id)?.stockLocationId || '')?.name || ''}`}
          />
        </Grid>
      </Grid>
    </ScrollableFilterContent>
  );
}

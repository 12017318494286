import React from 'react';
import { TagRelation } from '../types/tagRelation';

export interface TagRelationContextProps {
  tagRelations: Map<string, TagRelation>;
  setTagRelations: (tags: Map<string, TagRelation>) => void;
  tagRelationsLoading: boolean;
}

const defaultContext: TagRelationContextProps = {
  tagRelations: new Map(),
  setTagRelations: () => {},
  tagRelationsLoading: false,
};

export const TagRelationContext = React.createContext<TagRelationContextProps>(defaultContext);

import React, { useContext, useState } from 'react';
import { t } from '../../../../types/translation/Translator';
import { CompanyContext } from '../../../../context/CompanyContext';
import { FlexPane } from '../../../../VentoryUI/components/common/FlexPane/FlexPane';
import { StockLocationRoleAssignmentContext } from '../../../../context/StockLocationRoleAssignmentContext';
import { CompanyRoleAssignmentContext } from '../../../../context/CompanyRoleAssignmentContext';
import { CompanyRole, CompanyRoleAssignment } from '../../../../types/companyRoleAssignment';
import { StockLocation } from '../../../../types/stockLocation';
import Table from '../../../../VentoryUI/components/common/Table/Table';
import SearchBarWithFilter from '../../../../VentoryUI/components/common/SearchBarWithFilter/SearchBarWithFilter';
import { CompanyRoleAssignmentFilter } from '../../../../VentoryUI/components/filters/Filter/common/CompanyRoleAssignmentFilter';
import { UserContext } from '../../../../context/UserContext';
import { StockLocationRole, stockLocationRoleToString } from '../../../../types/stockLocationRoleAssignment';
import Checkbox from '../../../../VentoryUI/components/common/Checkbox/Checkbox';

interface StockLocationUserInputProps {
  stockLocation: StockLocation;
  setStockLocation: (location: StockLocation) => void;
  footer: (input: StockLocation) => JSX.Element;
}

export default function StockLocationUserPane({
  stockLocation,
  footer,
  setStockLocation,
}: StockLocationUserInputProps) {
  const { currentCompany } = useContext(CompanyContext);
  const { companyRoles } = useContext(CompanyRoleAssignmentContext);
  const { stockLocationRoles } = useContext(StockLocationRoleAssignmentContext);
  const { companyUsers } = useContext(UserContext);

  const [users, setUsers] = useState<CompanyRoleAssignment[]>(
    [...companyRoles.values()]
      .flat()
      .filter(
        cr =>
          cr.companyId === currentCompany.id &&
          (cr.role === CompanyRole.administrator ||
            [...stockLocationRoles.values()].flat().filter(sr => sr.stockLocationId === stockLocation.id).length),
      ),
  );
  const [items, setItems] = useState(users);

  const headers = [
    {
      key: 'name',
      name: t().name.singular.label,
      text: (item: CompanyRoleAssignment) => item.email,
    },
    {
      key: 'name',
      name: t().firstAndLastName.singular.label,
      text: (item: CompanyRoleAssignment) =>
        `${companyUsers.get(item.userId)?.firstName} ${companyUsers.get(item.userId)?.lastName}` || '',
    },
    {
      key: 'stockLocationRole',
      name: t().stockLocationRole.singular.label,
      text: (item: CompanyRoleAssignment) =>
        item.role === CompanyRole.administrator
          ? `${stockLocationRoleToString(StockLocationRole.STOCK_LOCATION_MANAGER)} (${
              t().administrator.singular.label
            })`
          : stockLocationRoleToString(
              [...stockLocationRoles.values()].flat().find(sr => sr.stockLocationId === stockLocation.id)?.role ||
                StockLocationRole.STOCK_LOCATION_VIEWER,
            ),
    },
    {
      key: 'primary',
      name: t().primaryContact.singular.label,
      text: (item: CompanyRoleAssignment) => (
        <Checkbox
          value={stockLocation.administrators.includes(item.userId)}
          onChange={v => {
            v
              ? setStockLocation(stockLocation.withAdministrator(item.userId))
              : setStockLocation(stockLocation.withoutAdministrator(item.userId));
          }}
        />
      ),
    },
  ];

  return (
    <>
      <FlexPane
        header={
          <SearchBarWithFilter
            items={users}
            setItems={setItems}
            placeholder={t().filterUsers.singular.label}
            filter={new CompanyRoleAssignmentFilter([...companyRoles.values()].flat())}
          />
        }
        content={<Table items={items} headers={headers} />}
        footer={footer(stockLocation)}
      />
    </>
  );
}

import { gql } from '@apollo/client';
import { AddressFragments } from './common/address.graphql';
import { forPaginated } from './common/paginated.graphql';
import {
  CreateStockLocationInput,
  DeleteStockLocationInput,
  StockLocation,
  UpdateStockLocationInput,
} from '../types/stockLocation';

const stockLocation = gql`
  fragment StockLocation on StockLocation {
    id
    version
    companyId
    name
    identifier
    mobile
    image
    administrators
    address {
      ...Address
    }
  }
  ${AddressFragments.address}
`;

const get = gql`
  query stockLocations($companyId: String!) {
    stockLocations(companyId: $companyId) {
      data {
        ...StockLocation
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${stockLocation}
`;

export interface GetStockLocationsVariables {
  companyId: string;
}

export interface GetStockLocationsResponse {
  stockLocations: PaginatedStockLocations;
}

class PaginatedStockLocations extends forPaginated<StockLocation>() {}

const update = gql`
  mutation UpdateStockLocation($stockLocations: [UpdateStockLocationInput!]!) {
    updateStockLocation(stockLocations: $stockLocations) {
      ...StockLocation
    }
  }
  ${stockLocation}
`;

export interface UpdateStockLocationVariables {
  stockLocations: UpdateStockLocationInput[];
}

export interface UpdateStockLocationResponse {
  updateStockLocation: StockLocation[];
}

const remove = gql`
  mutation DeleteStockLocation($stockLocations: [DeleteStockLocationInput!]!) {
    deleteStockLocation(stockLocations: $stockLocations) {
      ...StockLocation
    }
  }
  ${stockLocation}
`;

export interface DeleteStockLocationVariables {
  stockLocations: DeleteStockLocationInput[];
}

export interface DeleteStockLocationResponse {
  deleteStockLocation: StockLocation[];
}

const create = gql`
  mutation CreateStockLocation($stockLocations: [CreateStockLocationInput!]!) {
    createStockLocation(stockLocations: $stockLocations) {
      ...StockLocation
    }
  }
  ${stockLocation}
`;

export interface CreateStockLocationVariables {
  stockLocations: CreateStockLocationInput[];
}

export interface CreateStockLocationResponse {
  createStockLocation: StockLocation[];
}

const createWithBinCsv = gql`
  mutation CreateStockLocationWithBinCsv(
    $configurationId: String!
    $csvFileAsBase64Data: String!
    $stockLocation: CreateStockLocationInput!
  ) {
    createStockLocationWithBinCsv(
      configurationId: $configurationId
      csvFileAsBase64Data: $csvFileAsBase64Data
      stockLocation: $stockLocation
    ) {
      ...StockLocation
    }
  }
  ${stockLocation}
`;

export interface CreateStockLocationWithBinCsvVariables {
  stockLocation: CreateStockLocationInput;
  csvFileAsBase64Data: string;
  configurationId: string;
}

export interface CreateStockLocationWithBinCsvResponse {
  createStockLocationWithBinCsv: StockLocation;
}

export const StockLocationMutations = {
  update,
  remove,
  create,
  createWithBinCsv,
};

export const StockLocationQueries = {
  get,
};

export const StockLocationFragments = {
  stockLocation,
};

import { cloneDeep } from '@apollo/client/utilities';
import { Address, Coordinates } from './common/address';
import { CompanyEntity, forCreate, forDelete, forUpdate } from './common/entity';

enum StockLocationValidationError {
  name = 'Please enter a name',
}

export class StockLocation extends CompanyEntity {
  name!: string;
  identifier?: string;
  mobile?: boolean;
  image?: string;
  address?: Address;
  administrators: string[] = [];

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    Object.assign(this, cloneDeep(obj));
  }

  override forUpdate(): UpdateStockLocationInput {
    if (this.address) {
      if (!this.address.addressLine1) this.address.addressLine1 = undefined;
      if (!this.address.addressLine2) this.address.addressLine2 = undefined;
      if (!this.address.city) this.address.city = undefined;
      if (!this.address.coordinates) this.address.coordinates = undefined;
      if (!this.address.countryCode) this.address.countryCode = undefined;
      if (!this.address.postalCode) this.address.postalCode = undefined;
      if (!this.address.region) this.address.region = undefined;
    }

    return UpdateStockLocationInput.from(this, UpdateStockLocationInput);
  }

  override forDelete(): DeleteStockLocationInput {
    return DeleteStockLocationInput.from(this, DeleteStockLocationInput);
  }

  override validate(fields: (keyof StockLocation)[]) {
    return this.validateEntity(fields, field => {
      if (field === 'name' && !this.name) return StockLocationValidationError.name;
      return null;
    });
  }

  withName(name: string) {
    this.name = name;
    return cloneDeep(this);
  }

  withIdentifier(identifier: string) {
    this.identifier = identifier;
    return cloneDeep(this);
  }

  withMobile(mobile: boolean) {
    this.mobile = mobile;
    return cloneDeep(this);
  }

  withImage(image?: string) {
    this.image = image;
    return cloneDeep(this);
  }

  withAddress(address: Address) {
    this.address = address;
    return cloneDeep(this);
  }

  withAddressLine1(line: string) {
    if (!this.address) this.address = {};
    this.address.addressLine1 = line;
    return cloneDeep(this);
  }

  withAddressLine2(line: string) {
    if (!this.address) this.address = {};
    this.address.addressLine2 = line;
    return cloneDeep(this);
  }

  withCity(city: string) {
    if (!this.address) this.address = {};
    this.address.city = city;
    return cloneDeep(this);
  }

  withRegion(region: string) {
    if (!this.address) this.address = {};
    this.address.region = region;
    return cloneDeep(this);
  }

  withPostalCode(postalCode: string) {
    if (!this.address) this.address = {};
    this.address.postalCode = postalCode;
    return cloneDeep(this);
  }

  withCountryCode(countryCode?: string) {
    if (!this.address) this.address = {};
    this.address.countryCode = countryCode;
    return cloneDeep(this);
  }

  withCoordinates(coordinates?: Coordinates) {
    if (!this.address) this.address = {};
    this.address.coordinates = coordinates;
    return cloneDeep(this);
  }

  withAdministrator(userId: string) {
    const ids = new Set(this.administrators);
    ids.add(userId);
    this.administrators = [...ids.values()];
    return cloneDeep(this);
  }

  withoutAdministrator(userId: string) {
    const ids = new Set(this.administrators);
    ids.delete(userId);
    this.administrators = [...ids.values()];
    return cloneDeep(this);
  }
}

export class CreateStockLocationInput extends forCreate(StockLocation) {}

export class UpdateStockLocationInput extends forUpdate(StockLocation) {}

export class DeleteStockLocationInput extends forDelete(StockLocation) {}

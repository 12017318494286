import React, { useContext, useMemo, useState } from 'react';
import { ProductMasterData } from '../../../../types/productMasterData';
import { ProductContext } from '../../../../context/ProductContext';
import { Grid } from '@mui/material';
import { t } from '../../../../types/translation/Translator';
import { useNavigate } from 'react-router-dom';
import { StockLocationContext } from '../../../../context/StockLocationContext';
import { Product } from '../../../../types/product';
import { ProductMasterDataContext } from '../../../../context/ProductMasterDataContext';
import { LotContext } from '../../../../context/LotContext';
import { BinContext } from '../../../../context/BinContext';
import { quantityWithSuffix, toQuantityString } from '../../../../types/unitOfMeasure';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import { ProductInfoPaneTableHeaderKey } from '../../../../util/identifiers/ProductInfoPaneTableHeaderKey';
import QuickActionModal from './Modals/QuickActionModal';
import SearchBarWithFilter from '../../../../VentoryUI/components/common/SearchBarWithFilter/SearchBarWithFilter';
import { StockLocationRoleAssignmentContext } from '../../../../context/StockLocationRoleAssignmentContext';
import { CompanyContext } from '../../../../context/CompanyContext';
import { StockLocationRole } from '../../../../types/stockLocationRoleAssignment';
import { AddButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/AddButton';
import { FlexPane } from '../../../../VentoryUI/components/common/FlexPane/FlexPane';
import Paper from '../../../../VentoryUI/components/common/Paper/Paper';
import Table from '../../../../VentoryUI/components/common/Table/Table';
import FileViewLoader from '../../../../VentoryUI/components/common/File/FileViewLoader';
import { ProductFilter } from '../../../../VentoryUI/components/filters/Filter/ProductFilter';
import { BinStatusContext } from '../../../../context/BinStatusContext';
import { TagRelationContext } from '../../../../context/TagRelationContext';

interface ProductInfoPaneInputProps {
  productMasterData?: ProductMasterData;
  footer: () => JSX.Element;
}

export default function ProductInfoPane({ productMasterData: pmd, footer }: ProductInfoPaneInputProps) {
  const navigate = useNavigate();

  const { currentCompany } = useContext(CompanyContext);
  const { products, productsLoading } = useContext(ProductContext);
  const { filteredStockLocations } = useContext(StockLocationContext);
  const { productMasterData, productMasterDataLoading } = useContext(ProductMasterDataContext);
  const { binStatuses } = useContext(BinStatusContext);
  const { lots } = useContext(LotContext);
  const { bins } = useContext(BinContext);
  const { hasStockLocationRole } = useContext(StockLocationRoleAssignmentContext);
  const { tagRelations } = useContext(TagRelationContext);

  const [quickActionModalOpen, setQuickActionModalOpen] = useState<boolean>(false);
  const [product, setProduct] = useState<Product | undefined>(undefined);

  if (!pmd) return null; // TODO: Entity not found

  const headers = [
    {
      key: ProductInfoPaneTableHeaderKey.stockLocation,
      name: t().stockLocation.singular.label,
      text: (item: Product) => filteredStockLocations.get(item.stockLocationId)?.name || 'Unknown Stock Location',
    },
    {
      key: ProductInfoPaneTableHeaderKey.bin,
      name: t().bin.singular.label,
      text: (item: Product) => bins.get(item.binId)?.name || 'Unknown Bin',
    },
    {
      key: ProductInfoPaneTableHeaderKey.lpn,
      name: t().lpn.singular.label,
      text: (item: Product) => item.lpn || 'No LPN',
    },
    {
      key: ProductInfoPaneTableHeaderKey.serialNumber,
      name: t().serial.singular.label,
      text: (item: Product) => item.serial || 'No serial',
    },
    {
      key: ProductInfoPaneTableHeaderKey.lotNumber,
      name: t().lotNumber.singular.label,
      text: (item: Product) => lots.get(item.lotId || '')?.number || 'No Lot',
    },
    {
      key: ProductInfoPaneTableHeaderKey.quantity,
      name: t().quantity.singular.label,
      text: (item: Product) =>
        quantityWithSuffix(item.quantity, productMasterData.get(item.productMasterDataId)?.unitOfMeasure),
    },
  ].filter(item => {
    if (item.key === 'serialNumber' && !pmd.serialManaged) return false;
    if (item.key === 'lpn' && !pmd.lpnManaged) return false;
    if (item.key === 'lotNumber' && !pmd.lotManaged) return false;

    return true;
  });

  const [items, setItems] = useState<Product[]>([...(products.get(pmd.id)?.values() || [])]);

  const quantity = toQuantityString(
    items.reduce((sum, p) => sum + BigInt(p.unitQuantity), BigInt(0)).toString(),
    productMasterData.get(pmd.id)?.unitOfMeasure,
  );

  const buttons = useMemo(() => {
    const hasManagerRoleInAStockLocation = !![...filteredStockLocations.values()].filter(item =>
      hasStockLocationRole(currentCompany.id, item.id, StockLocationRole.STOCK_LOCATION_MANAGER),
    ).length;

    if (!hasManagerRoleInAStockLocation) return [];

    return [AddButtonTemplate(() => setQuickActionModalOpen(true))];
  }, [filteredStockLocations]);

  const allItems = useMemo(() => {
    return [...(products.get(pmd.id)?.values() || [])];
  }, [products]);

  return (
    <>
      <QuickActionModal
        open={quickActionModalOpen}
        setOpen={c => {
          setQuickActionModalOpen(c);
          setProduct(undefined);
        }}
        pmdId={pmd.id}
        productId={product?.id}
      />
      <FlexPane
        testId={testIds.productInfoPane}
        header={
          <Paper>
            <Grid container>
              <Grid item xs={6} my='auto'>
                <Grid container>
                  {pmd.image ? (
                    <Grid
                      item
                      xs={2}
                      display='flex'
                      pr={1}
                      alignContent={'center'}
                      justifyContent='center'
                      maxHeight='inherit'
                    >
                      <FileViewLoader fileId={pmd.image} />
                    </Grid>
                  ) : null}
                  <Grid item xs={pmd.image ? 10 : 12} my='auto'>
                    <p className='font-semibold'>{t().productName.singular.label}</p>
                    <p
                      className='cursor-pointer text-xl font-bold text-gray-400 mt-2 w-fit line-clamp-2'
                      onClick={() => navigate(`/reference_data/pmd/${pmd.id}/update/info`)}
                      style={{ lineClamp: 2 }}
                      data-testid={testIds.productName}
                    >
                      {pmd.productName}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} my='auto'>
                <p className='font-semibold'>{t().productNumber.singular.label}</p>
                <p
                  className='cursor-pointer text-xl font-bold text-gray-400 mt-2 w-fit line-clamp-2'
                  onClick={() => navigate(`/reference_data/pmd/${pmd.id}/update/info`)}
                  data-testid={testIds.productNumber}
                >
                  {pmd.productNumber}
                </p>
              </Grid>
              <Grid item xs={2} my='auto' textAlign={'right'}>
                <p className='font-semibold'>{t().quantity.singular.label}</p>
                <p className='text-xl font-bold text-gray-400 mt-2' data-testid={testIds.totalQuantity}>
                  {quantityWithSuffix(quantity, pmd.unitOfMeasure)}
                </p>
              </Grid>
            </Grid>
          </Paper>
        }
        content={
          <FlexPane
            header={
              <SearchBarWithFilter
                loading={productsLoading || productMasterDataLoading}
                items={allItems}
                setItems={setItems}
                placeholder={t().filterProducts.singular.label}
                testId={testIds.product}
                buttons={buttons}
                filter={new ProductFilter(productMasterData, binStatuses, tagRelations)}
              />
            }
            content={
              <Table
                loading={!items.length && (productsLoading || productMasterDataLoading)}
                items={items}
                totalItemCount={allItems.length}
                headers={headers}
                onClick={item => {
                  setProduct(item);
                  setQuickActionModalOpen(true);
                }}
              />
            }
          />
        }
        footer={footer()}
      />
    </>
  );
}

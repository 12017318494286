import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ProductMasterData } from '../../../../types/productMasterData';
import { Grid } from '@mui/material';
import { t } from '../../../../types/translation/Translator';
import { useNavigate } from 'react-router-dom';
import { LotContext } from '../../../../context/LotContext';
import { ProductTransaction, ProductTransactionParentType } from '../../../../types/productTransaction';
import { ProductTransactionContext } from '../../../../context/ProductTransactionContext';
import { UserContext } from '../../../../context/UserContext';
import { iconForType } from '../../../../util/productTransactions.util';
import ProductTransactionDetailModal from '../Modals/ProductTransactionDetailModal';
import { ProductMasterDataContext } from '../../../../context/ProductMasterDataContext';
import { quantityWithSuffix } from '../../../../types/unitOfMeasure';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import Table from '../../../../VentoryUI/components/common/Table/Table';
import { FlexPane } from '../../../../VentoryUI/components/common/FlexPane/FlexPane';
import SearchBarWithFilter from '../../../../VentoryUI/components/common/SearchBarWithFilter/SearchBarWithFilter';
import { SystemUser } from '../../../../types/user';
import { ProductTransactionFilter } from '../../../../VentoryUI/components/filters/Filter/ProductTransaction/ProductTransactionFilter';
import { OrderContext } from '../../../../context/OrderContext';
import { TagRelationContext } from '../../../../context/TagRelationContext';

interface ProductHistoryPaneInputProps {
  productMasterData?: ProductMasterData;
  footer: () => JSX.Element;
}

export default function ProductHistoryPane({ productMasterData: pmd, footer }: ProductHistoryPaneInputProps) {
  const navigate = useNavigate();

  const { lots } = useContext(LotContext);
  const { productTransactions } = useContext(ProductTransactionContext);
  const { companyUsers } = useContext(UserContext);
  const { productMasterData } = useContext(ProductMasterDataContext);
  const { orders } = useContext(OrderContext);
  const { tagRelations } = useContext(TagRelationContext);

  const [openDetailModal, setOpenDetailModal] = useState<boolean>(false);
  const [selectedTransaction, setSelectedTransaction] = useState<ProductTransaction | null>(null);

  if (!pmd) return null; // TODO: Entity not found

  const items = useMemo(() => {
    return [...productTransactions.values()]
      .filter(p => p.product.pmdId === pmd.id && p.parentType !== ProductTransactionParentType.transaction)
      .sort(
        (a, b) => new Date(b.processedAt || b.createdAt).getTime() - new Date(a.processedAt || a.createdAt).getTime(),
      );
  }, [productTransactions]);

  const [filteredItems, setFilteredItems] = useState(items);

  const headers = [
    {
      key: 'processedAt',
      name: t().processedAt.singular.label,
      text: (item: ProductTransaction) => {
        return `${new Date(item.processedAt || item.createdAt).toLocaleDateString()} - ${new Date(
          item.processedAt || item.createdAt,
        ).toLocaleTimeString()}`;
      },
      sortValue: (item: ProductTransaction) => item.processedAt || item.createdAt,
    },
    {
      key: 'processedBy',
      name: t().processedBy.singular.label,
      text: (item: ProductTransaction) => {
        if (!item.processedBy) return '';
        return (
          companyUsers.get(item.processedBy || '')?.email ||
          (item.processedBy === SystemUser.id ? SystemUser.email : 'Unknown User')
        );
      },
    },
    {
      key: 'serialNumber',
      name: t().serial.singular.label,
      text: (item: ProductTransaction) => item.product.serialNbr || '',
    },
    {
      key: 'lpn',
      name: t().lpn.singular.label,
      text: (item: ProductTransaction) => item.product.lpn || '',
    },
    {
      key: 'lotNumber',
      name: t().lotNumber.singular.label,
      text: (item: ProductTransaction) => lots.get(item.product.lotId || '')?.number || '',
    },
    {
      key: 'quantity',
      name: t().quantity.singular.label,
      sortValue: (item: ProductTransaction) => item.product.processedQuantity || item.product.quantity,
      text: (item: ProductTransaction) => {
        return (
          <Grid container columnSpacing={1} justifyContent={'flex-end'}>
            <Grid item marginY={'auto'} className='h-7'>
              <p
                title={item.product.quantity.toString()}
                className={`h-7 text-ellipsis py-1 overflow-hidden text-sm whitespace-nowrap`}
              >
                {quantityWithSuffix(
                  (item.product.processedQuantity || item.product.quantity).toString(),
                  productMasterData.get(item.product.pmdId || '')?.unitOfMeasure,
                )}
              </p>
            </Grid>
            <Grid item marginY={'auto'}>
              {iconForType(item)}
            </Grid>
          </Grid>
        );
      },
    },
  ].filter(item => {
    if (item.key === 'serialNumber' && !pmd.serialManaged) return false;
    if (item.key === 'lpn' && !pmd.lpnManaged) return false;
    if (item.key === 'lotNumber' && !pmd.lotManaged) return false;

    return true;
  });

  useEffect(() => {
    setFilteredItems(items);
  }, [items]);

  return (
    <>
      {selectedTransaction ? (
        <ProductTransactionDetailModal
          open={openDetailModal}
          setOpen={v => {
            setOpenDetailModal(v);
            setSelectedTransaction(null);
          }}
          transaction={selectedTransaction}
        />
      ) : null}

      <FlexPane
        testId={testIds.productHistoryPane}
        header={
          <SearchBarWithFilter
            items={items}
            setItems={setFilteredItems}
            placeholder={t().filterProductTransactions.singular.label}
            filter={new ProductTransactionFilter(companyUsers, productMasterData, orders, tagRelations)}
          />
        }
        content={
          <Table
            items={filteredItems}
            headers={headers}
            totalItemCount={items.length}
            onClick={item => {
              setSelectedTransaction(item);
              setOpenDetailModal(true);
            }}
          />
        }
        footer={footer()}
      />
    </>
  );
}

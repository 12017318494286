import React, { JSX } from 'react';
import { toFilterString } from '../../../../../util/string.util';
import { t } from '../../../../../types/translation/Translator';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { BaseFilter, BaseFilterProps } from '../common/BaseFilter';
import { FilterProps } from '../common/filter.util';
import FileTypeFilterContent from '../../DynamicEntityFilter/Content/FileTypeFilterContent';
import FileTypeFilterInnerContent from '../../DynamicEntityFilter/InnerContent/FileTypeFilterInnerContent';

export class FileTypeFilter extends BaseFilter<string> {
  toLabel(): string {
    return t().fileType.singular.label;
  }

  toCount(props: BaseFilterProps): number {
    return props.filterProps.fileType?.size || 0;
  }

  toIcon(): JSX.Element {
    return <InsertDriveFileOutlinedIcon className={this.className + ' stroke-none'} />;
  }

  toDropdownContent(props: BaseFilterProps): JSX.Element {
    return (
      <FileTypeFilterContent filter={this} filterProps={props.filterProps} setFilterProps={props.setFilterProps} />
    );
  }

  toInnerContent(props: BaseFilterProps): string | JSX.Element {
    return (
      <FileTypeFilterInnerContent filter={this} filterProps={props.filterProps} setFilterProps={props.setFilterProps} />
    );
  }

  search(fileType: string, textFilter: string) {
    if (toFilterString(fileType).includes(textFilter)) return true;

    return false;
  }

  filteredItems(items: string[], query: string, filterProps: FilterProps) {
    return items.filter(item => {
      return this.search(item, query);
    });
  }
}

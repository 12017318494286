import React from 'react';
import { ReorderRuleGroup } from '../../../../../components/Common/ReorderRules/ReorderRulesOverviewPane';
import { toFilterString } from '../../../../../util/string.util';
import { BaseFilter } from '../common/BaseFilter';

export class ReorderRuleFilter extends BaseFilter<ReorderRuleGroup> {
  toLabel(): string {
    return 'Not Implemented';
  }

  toCount(): number {
    return 0;
  }

  toIcon(): JSX.Element {
    return <></>;
  }

  toDropdownContent(): JSX.Element {
    return <></>;
  }

  toInnerContent(): string | JSX.Element {
    return <></>;
  }

  search(item: ReorderRuleGroup, textFilter: string) {
    if (toFilterString(item.rules[0].name).includes(textFilter)) {
      return true;
    }

    return false;
  }
}

import React, { useContext, useState } from 'react';
import { CompanyContext } from '../../../../../context/CompanyContext';
import { TagContext } from '../../../../../context/TagContext';
import { CreateTagResponse, CreateTagVariables, TagMutations } from '../../../../../graphql/tag.graphql';
import { Tag } from '../../../../../types/tag';
import { useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import CancelButton from '../../../../../VentoryUI/components/common/Button/Templates/CancelButton';
import CreateButton from '../../../../../VentoryUI/components/common/Button/Templates/CreateButton';
import Modal from '../../../../../VentoryUI/components/common/Modal/Modal';
import { t } from '../../../../../types/translation/Translator';
import TagItem from '../../Common/Tag/TagItem';

interface CreateTagModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export default function CreateTagModal({ open, setOpen }: CreateTagModalProps) {
  const { currentCompany } = useContext(CompanyContext);
  const { tags, setTags } = useContext(TagContext);

  const [error, setError] = useState<string>('');

  const [create, { loading }] = useMutation<CreateTagResponse, CreateTagVariables>(TagMutations.create, {
    onCompleted: res => {
      const tag = res.createTag[0];
      tags.set(tag.id, new Tag(tag));
      setTags(new Map(tags));
      handleClose();
    },
    onError: res => setError(res.message),
  });

  const handleClose = () => {
    setOpen(false);
    setError('');
  };

  const handleCreate = async (tag: Tag) => {
    try {
      await create({
        variables: {
          tags: [tag],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const footer = (field: Tag) => {
    return (
      <Grid container columnSpacing={1} justifyContent={'flex-end'}>
        <Grid item>
          <CancelButton disabled={loading} onClick={handleClose} />
        </Grid>
        <Grid item>
          <CreateButton loading={loading} disabled={!field.name || !field.type} onClick={() => handleCreate(field)} />
        </Grid>
      </Grid>
    );
  };

  return (
    <Modal
      open={open}
      height='250px'
      width='60%'
      onClose={handleClose}
      title={t().createTag.singular.label}
      error={error}
    >
      <TagItem setError={setError} tag={new Tag({ companyId: currentCompany.id })} footer={footer} />
    </Modal>
  );
}

import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import { SectionTitle } from '../SectionTitle/SectionTitle';
import { StaticTimeFilter } from '../../filters/StaticTimeFilter/StaticTimeFilter';
import { NumberChartContainer } from '../../../../components/Dashboard/Graphs/Containers/NumberChartContainer';
import { BarChartContainer } from '../../../../components/Dashboard/Graphs/Containers/BarChartContainer';
import { NumberChartType } from '../../charts/NumberChart/NumberChart';
import { t } from '../../../../types/translation/Translator';
import { StaticTimeFilterType } from '../../filters/StaticTimeFilter/StaticTimeFilterType';
import { FilterProps, FilterStorageKey } from '../../filters/Filter/common/filter.util';
import { CompanyContext } from '../../../../context/CompanyContext';

interface OverviewMetricsProps {
  color: string;
}

export default function OverviewMetrics({ color }: OverviewMetricsProps) {
  const { currentCompany } = useContext(CompanyContext);

  const [filter, setFilter] = useState<FilterProps>(
    new FilterProps(StaticTimeFilterType.getTimeFrame(StaticTimeFilterType.WEEK), StaticTimeFilterType.WEEK),
  );

  const hasTasks = !!currentCompany.settings.featureToggles.tasks.tasks;
  const hasOrders = !!currentCompany.settings.featureToggles.orders.orders;

  return (
    <>
      <Grid item xs={12}>
        <SectionTitle text={t().overview.singular.label} />
      </Grid>
      <Grid item xs={12}>
        <StaticTimeFilter filter={filter} setFilter={setFilter} />
      </Grid>
      <Grid item xs={12}>
        <Grid container columnSpacing={1}>
          <Grid item xs={12} sm={12} md={4} lg={2}>
            <Grid container columnSpacing={1} display={'flex'} className='h-full'>
              <NumberChartContainer
                filter={filter}
                type={NumberChartType.text}
                entity={FilterStorageKey.STOCK_LOCATION}
                size={{ xs: 12, sm: 4, md: 12 }}
                text={t().stockLocation.plural.label}
                color={color}
              />
              {hasTasks ? (
                <NumberChartContainer
                  filter={filter}
                  type={NumberChartType.both}
                  entity={FilterStorageKey.TASK}
                  size={{ xs: 12, sm: 4, md: 12 }}
                  text={t().tasks.singular.label}
                  color={color}
                />
              ) : (
                <NumberChartContainer
                  filter={filter}
                  type={NumberChartType.text}
                  entity={FilterStorageKey.PRODUCT_TRANSACTION}
                  size={{ xs: 12, sm: 4, md: 12 }}
                  text={t().transactions.singular.label}
                  color={color}
                />
              )}
              {hasOrders ? (
                <NumberChartContainer
                  filter={filter}
                  type={NumberChartType.both}
                  entity={FilterStorageKey.ORDER}
                  size={{ xs: 12, sm: 4, md: 12 }}
                  text={t().order.plural.label}
                  color={color}
                />
              ) : (
                <NumberChartContainer
                  filter={filter}
                  type={NumberChartType.text}
                  entity={FilterStorageKey.PRODUCT_TRANSACTION}
                  size={{ xs: 12, sm: 4, md: 12 }}
                  text={t().transactions.singular.label}
                  color={color}
                />
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={10} display={'flex'}>
            <BarChartContainer
              entity={FilterStorageKey.PRODUCT_TRANSACTION}
              text={t().numberOfTransactions.singular.label}
              color={color}
              filter={filter}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

import { gql } from '@apollo/client';
import { IdArray } from '../types/common/entity';
import { forPaginated } from './common/paginated.graphql';
import { CreateTagInput, DeleteTagInput, Tag, UpdateTagInput } from '../types/tag';

const tag = gql`
  fragment Tag on Tag {
    id
    version
    companyId
    name
    type
    description
    color
  }
`;

const get = gql`
  query Tags($companyId: String!, $page: Float) {
    tags(companyId: $companyId, page: $page) {
      data {
        ...Tag
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${tag}
`;

export interface GetTagsVariables {
  companyId: string;
  page?: number;
}

export interface GetTagsResponse {
  tags: PaginatedTags;
}

class PaginatedTags extends forPaginated<Tag>() {}

const create = gql`
  mutation CreateTag($tags: [CreateTagInput!]!) {
    createTag(tags: $tags) {
      ...Tag
    }
  }
  ${tag}
`;

export interface CreateTagVariables {
  tags: CreateTagInput[];
}

export interface CreateTagResponse {
  createTag: Tag[];
}

const update = gql`
  mutation UpdateTag($tags: [UpdateTagInput!]!) {
    updateTag(tags: $tags) {
      ...Tag
    }
  }
  ${tag}
`;

export interface UpdateTagVariables {
  tags: UpdateTagInput[];
}

export interface UpdateTagResponse {
  updateTag: Tag[];
}

const remove = gql`
  mutation DeleteTag($tags: [DeleteTagInput!]!) {
    deleteTag(tags: $tags) {
      ...Tag
    }
  }
  ${tag}
`;

export interface DeleteTagVariables {
  tags: DeleteTagInput[];
}

export interface DeleteTagResponse {
  deleteTag: IdArray;
}

export const TagQueries = {
  get,
};

export const TagMutations = {
  create,
  update,
  remove,
};

export const TagFragment = {
  tag,
};

import React from 'react';
import { BaseFilter, BaseFilterProps } from '../common/BaseFilter';
import { t } from '../../../../../types/translation/Translator';
import { ListIcon } from '../../../../icons/List/ListIcon';
import { StockLocation } from '../../../../../types/stockLocation';
import TaskStatusFilterDropdownContent from '../../DynamicEntityFilter/Content/TaskStatusFilterContent';
import TaskStatusFilterInnerContent from '../../DynamicEntityFilter/InnerContent/TaskStatusFilterInnerContent';
import { TaskStatus } from '../../../../../types/task';
import { toFilterString } from '../../../../../util/string.util';
import { FilterProps } from '../common/filter.util';

export class TaskStatusFilter extends BaseFilter<string> {
  stockLocations: Map<string, StockLocation>;

  constructor(stockLocations: Map<string, StockLocation>) {
    super();
    this.stockLocations = stockLocations;
  }

  toLabel(): string {
    return t().taskStatus.singular.label;
  }

  toCount(props: BaseFilterProps): number {
    return props.filterProps.taskStatus?.size || 0;
  }

  toIcon(): JSX.Element {
    return <ListIcon className={this.className} />;
  }

  toDropdownContent(props: BaseFilterProps): JSX.Element {
    return (
      <TaskStatusFilterDropdownContent
        filter={this}
        filterProps={props.filterProps}
        setFilterProps={props.setFilterProps}
      />
    );
  }

  toInnerContent(props: BaseFilterProps): string | JSX.Element {
    return (
      <TaskStatusFilterInnerContent
        filter={this}
        filterProps={props.filterProps}
        setFilterProps={props.setFilterProps}
      />
    );
  }

  search(item: TaskStatus | 'Overdue', filter: string) {
    if (toFilterString(item).includes(filter)) {
      return true;
    }
    return false;
  }

  filteredItems(items: (TaskStatus | 'Overdue')[], query: string, filterProps: FilterProps): string[] {
    return items.filter(item => {
      return this.search(item, query);
    });
  }
}

import React, { useState } from 'react';
import { t } from '../../../../types/translation/Translator';
import { ProductTransaction } from '../../../../types/productTransaction';
import ProcessOrderTransactionInfoPane from '../../../Operation/Order/Modals/Panes/ProcessOrderTransactionInfoPane';
import Modal from '../../../../VentoryUI/components/common/Modal/Modal';

interface ProductTransactionDetailModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  transaction: ProductTransaction;
}

export default function ProductTransactionDetailModal({
  open,
  setOpen,
  transaction,
}: ProductTransactionDetailModalProps) {
  const [transactionInput, setTransactionInput] = useState<ProductTransaction>(new ProductTransaction(transaction));
  const [error, setError] = useState<string>('');

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal error={error} open={open} onClose={handleClose} height='650px' title={t().transactionInfo.singular.label}>
      <ProcessOrderTransactionInfoPane
        parentInfo
        transaction={transactionInput}
        setTransaction={setTransactionInput}
        setError={setError}
        onClose={handleClose}
        disabled
      />
    </Modal>
  );
}

import React, { useContext, useMemo, useState } from 'react';
import { t } from '../../types/translation/Translator';
import { useNavigate } from 'react-router-dom';
import { Task, TaskStatus, TaskType } from '../../types/task';
import { StockLocationContext } from '../../context/StockLocationContext';
import { TaskContext } from '../../context/TaskContext';
import CreateTaskModal from './Modals/CreateTaskModal';
import TaskIcon from '@mui/icons-material/Task';
import { useMutation } from '@apollo/client';
import { DownloadMultipleTextAsZip } from '../../util/download.util';
import moment from 'moment-timezone';
import 'moment/min/locales';
import DeleteTaskModal from './Modals/DeleteTaskModal';
import { ReportMutations, ReportTaskResponse, ReportTaskVariables } from '../../graphql/report.graphql';
import { CompanyContext } from '../../context/CompanyContext';
import { CompanyRoleAssignmentContext } from '../../context/CompanyRoleAssignmentContext';
import { testIds } from '../../util/identifiers/identifiers.util';
import { CompanyRole } from '../../types/companyRoleAssignment';
import TaskStatusLabel from '../Common/TaskStatusLabel';
import { StockLocationRoleAssignmentContext } from '../../context/StockLocationRoleAssignmentContext';
import { StockLocationRole } from '../../types/stockLocationRoleAssignment';
import SearchBarWithFilter from '../../VentoryUI/components/common/SearchBarWithFilter/SearchBarWithFilter';
import { MenuItemProps } from '../../VentoryUI/components/common/Menu/MenuItem';
import { ButtonProps } from '../../VentoryUI/components/common/Button/Button';
import { DeleteButtonTemplate } from '../../VentoryUI/components/common/Button/Templates/DeleteButton';
import { NewMenuItemTemplate } from '../../VentoryUI/components/common/Menu/Templates/NewMenuItem';
import { SettingsMenuItemTemplate } from '../../VentoryUI/components/common/Menu/Templates/SettingsMenuItem';
import Pane from '../../VentoryUI/components/common/Pane/Pane';
import { FlexPane } from '../../VentoryUI/components/common/FlexPane/FlexPane';
import Table from '../../VentoryUI/components/common/Table/Table';
import { TaskFilter } from '../../VentoryUI/components/filters/Filter/Task/TaskFilter';
import { UserContext } from '../../context/UserContext';
import { BinStatusContext } from '../../context/BinStatusContext';
import { ProductMasterDataContext } from '../../context/ProductMasterDataContext';
import { TagRelationContext } from '../../context/TagRelationContext';

interface TaskScreenInputProps {}

export default function TaskScreen({}: TaskScreenInputProps) {
  const navigate = useNavigate();

  const { tasks, tasksLoading } = useContext(TaskContext);
  const { filteredStockLocations } = useContext(StockLocationContext);
  const { companyUsers } = useContext(UserContext);
  const { currentCompany } = useContext(CompanyContext);
  const { hasCompanyRole } = useContext(CompanyRoleAssignmentContext);
  const { hasStockLocationRole } = useContext(StockLocationRoleAssignmentContext);
  const { binStatuses } = useContext(BinStatusContext);
  const { productMasterData } = useContext(ProductMasterDataContext);
  const { tagRelations } = useContext(TagRelationContext);

  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<Set<string>>(new Set());

  const [items, setItems] = useState<Task[]>([...tasks.values()]);

  const [download] = useMutation<ReportTaskResponse, ReportTaskVariables>(ReportMutations.task, {
    onCompleted: res => handleFileDownload(res),
  });

  const handleFileDownload = (res: ReportTaskResponse) => {
    const file = res.generateReportByTask;
    DownloadMultipleTextAsZip(
      file.map(f => {
        return { name: f.fileName, text: f.content };
      }),
      `ventory_task_${new Date().toISOString()}.zip`,
    );
  };

  const handleDownload = (item: Task) => {
    if (!item) return;
    if (item.status === TaskStatus.complete || item.status === TaskStatus.processed)
      download({
        variables: {
          taskId: item.id,
          timezone: moment.tz.guess(),
          locale: (window.navigator as any)['userLanguage'] || window.navigator.language,
          companyId: item.companyId,
          stockLocationId: item.stockLocationId,
        },
      });
    return;
  };

  const headers = [
    {
      key: 'number',
      name: t().number.singular.label,
      text: (item: Task) => {
        return item.number;
      },
    },
    {
      key: 'type',
      name: t().type.singular.label,
      text: (item: Task) => {
        if (item.parentId) return 'Recount';
        return item.type === TaskType.cycle ? 'Cycle Count' : 'Blind Count';
      },
    },
    {
      key: 'stockLocation',
      name: t().stockLocation.singular.label,
      text: (item: Task) => filteredStockLocations.get(item.stockLocationId)?.name || 'Unknown Stock Location',
    },
    {
      key: 'createdOn',
      name: t().createdAt.singular.label,
      text: (item: Task) => (item.createdAt ? new Date(item.createdAt).toLocaleDateString() : ''),
      sortValue: (item: Task) => item.createdAt,
    },
    {
      key: 'dueDate',
      name: t().dueDate.singular.label,
      text: (item: Task) => {
        return item.dueDate ? new Date(item.dueDate).toLocaleDateString() : '';
      },
      sortValue: (item: Task) => item.dueDate,
    },
    {
      key: 'completedOn',
      name: t().completedOn.singular.label,
      text: (item: Task) => (item.completedOn ? new Date(item.completedOn).toLocaleDateString() : ''),
      sortValue: (item: Task) => item.dueDate,
    },
    {
      key: 'report',
      name: '',
      text: (item: Task) =>
        item.status === TaskStatus.complete || item.status === TaskStatus.processed ? (
          <div
            className='h-7'
            onClick={event => {
              event.stopPropagation();
              handleDownload(item);
            }}
          >
            <TaskIcon />
          </div>
        ) : (
          ''
        ),
    },
    {
      key: 'status',
      name: t().status.singular.label,
      text: (item: Task) => <TaskStatusLabel task={item} />,
      sortValue: (item: Task) => item.status,
    },
  ];

  const menuItems: MenuItemProps[] = useMemo(() => {
    const shown: MenuItemProps[] = [];

    if (
      [...filteredStockLocations.values()].filter(sl =>
        hasStockLocationRole(currentCompany.id, sl.id, StockLocationRole.STOCK_LOCATION_SUPERVISOR),
      )
    ) {
      shown.push(NewMenuItemTemplate(() => setOpenCreateModal(true)));
    }

    if (hasCompanyRole(currentCompany.id, CompanyRole.administrator)) {
      shown.push(SettingsMenuItemTemplate(() => navigate('/tasks/settings/overview')));
    }

    return shown;
  }, [filteredStockLocations]);

  const buttons: ButtonProps[] = useMemo(() => {
    const shown: ButtonProps[] = [];

    if (
      [...filteredStockLocations.values()].filter(sl =>
        hasStockLocationRole(currentCompany.id, sl.id, StockLocationRole.STOCK_LOCATION_MANAGER),
      ) &&
      selected.size
    ) {
      shown.push(DeleteButtonTemplate(() => setOpenDeleteModal(true)));
    }

    return shown;
  }, [filteredStockLocations, selected]);

  const allItems = useMemo(() => {
    return [...tasks.values()];
  }, [tasks]);

  return (
    <>
      <CreateTaskModal open={openCreateModal} setOpen={setOpenCreateModal} />
      <DeleteTaskModal
        open={openDeleteModal}
        setOpen={v => {
          setOpenDeleteModal(v);
          setSelected(new Set());
        }}
        taskIds={selected}
      />
      <Pane testId={testIds.taskScreen}>
        <FlexPane
          header={
            <SearchBarWithFilter
              loading={tasksLoading}
              items={allItems}
              setItems={setItems}
              filter={
                new TaskFilter(filteredStockLocations, companyUsers, binStatuses, productMasterData, tagRelations)
              }
              placeholder={t().filterTasks.singular.label}
              menuItems={menuItems}
              buttons={buttons}
            />
          }
          content={
            <Table
              title={t().tasks.singular.label}
              loading={tasksLoading}
              totalItemCount={allItems.length}
              items={items}
              headers={headers}
              onClick={item => navigate(`/tasks/task/${item.id}/info`)}
              onSelected={items => setSelected(new Set(items))}
            />
          }
        />
      </Pane>
    </>
  );
}

import React from 'react';
import { ProductTransaction, ProductTransactionParentType } from '../../../../types/productTransaction';
import dayjs from 'dayjs';
import { BaseFilter } from './common/BaseFilter';
import { t } from '../../../../types/translation/Translator';
import { FilterProps, FilterStorageKey } from './common/filter.util';
import { StockLocationFilter } from './common/StockLocationFilter';
import { DateTimeFilter } from './common/DateTimeFilter';
import { ProductMasterDataFilter } from './common/ProductMasterDataFilter';
import { User } from '../../../../types/user';
import { ProductMasterData } from '../../../../types/productMasterData';
import { removeDiacritics, toFilterString } from '../../../../util/string.util';
import { Order } from '../../../../types/order';
import { TagRelation } from '../../../../types/tagRelation';
import { TagFilter } from './Tag/TagFilter';
import { EntityType } from '../../../../types/comment';
import { UserFilter } from './User/UserFilter';

export class ProductTransactionFilter extends BaseFilter<ProductTransaction> {
  users: Map<string, User>;
  productMasterData: Map<string, ProductMasterData>;
  orders: Map<string, Order>;
  tagRelations: Map<string, TagRelation>;

  constructor(
    users: Map<string, User>,
    productMasterData: Map<string, ProductMasterData>,
    orders: Map<string, Order>,
    tagRelations: Map<string, TagRelation>,
  ) {
    super(FilterStorageKey.PRODUCT_TRANSACTION);
    this.users = users;
    this.productMasterData = productMasterData;
    this.orders = orders;
    this.tagRelations = tagRelations;
  }

  toLabel(): string {
    return t().productMasterData.singular.label;
  }

  toCount(): number {
    return 0;
  }

  toIcon(): JSX.Element {
    return <></>;
  }

  toDropdownContent(): JSX.Element {
    return <></>;
  }

  toInnerContent(): string | JSX.Element {
    return <></>;
  }

  filterOptions = () => [
    new DateTimeFilter(),
    new StockLocationFilter(this.tagRelations),
    new ProductMasterDataFilter(),
    new UserFilter(this.users),
    new TagFilter(),
  ];

  search = (item: ProductTransaction, filter: string) => {
    const productMasterData = this.productMasterData.get(item.product.pmdId || '');
    const productName = removeDiacritics(toFilterString(productMasterData?.productName));
    const productNumber = removeDiacritics(toFilterString(productMasterData?.productNumber));

    const user = this.users.get(item.processedBy || '');
    const firstName = removeDiacritics(toFilterString(user?.firstName));
    const lastName = removeDiacritics(toFilterString(user?.lastName));
    const email = removeDiacritics(toFilterString(user?.email));

    const order = this.orders.get(item.parentId || '');
    const orderNumber = removeDiacritics(toFilterString(order?.number));

    const serial = removeDiacritics(toFilterString(item.product.serialNbr));
    const lpn = removeDiacritics(toFilterString(item.product.lpn));
    const countryOfOrigin = removeDiacritics(toFilterString(item.product.countryOfOrigin));

    if (
      productName.includes(filter) ||
      productNumber.includes(filter) ||
      firstName.includes(filter) ||
      lastName.includes(filter) ||
      email.includes(filter) ||
      orderNumber.includes(filter) ||
      serial.includes(filter) ||
      lpn.includes(filter) ||
      countryOfOrigin.includes(filter)
    ) {
      return true;
    }
    return false;
  };

  filteredItems(items: ProductTransaction[], query: string, filterProps: FilterProps) {
    const allowedStockLocationByTag = new Set<string>();
    if (filterProps.tags?.size) {
      for (const [key, relation] of this.tagRelations) {
        if (filterProps.tags.has(relation.tagId)) {
          if (relation.type === EntityType.stockLocation) allowedStockLocationByTag.add(relation.entityId);
        }
      }
    }

    const result = items.filter(item => {
      if (item.parentType === ProductTransactionParentType.transaction) return false;

      if (
        filterProps.timeFrame &&
        (dayjs(filterProps.timeFrame.start)
          .startOf('day')
          .isAfter(dayjs(item.processedAt || item.createdAt)) ||
          dayjs(filterProps.timeFrame.end)
            .endOf('day')
            .isBefore(dayjs(item.processedAt || item.createdAt)))
      ) {
        return false;
      }

      if (
        (filterProps.stockLocation &&
          filterProps.stockLocation.size &&
          item.product.fromStockLocationId &&
          !filterProps.stockLocation.has(item.product.fromStockLocationId)) ||
        (filterProps.stockLocation &&
          item.product.toStockLocationId &&
          !filterProps.stockLocation.has(item.product.toStockLocationId))
      ) {
        return false;
      }

      if (
        allowedStockLocationByTag.size &&
        ((item.product.fromStockLocationId && !allowedStockLocationByTag.has(item.product.fromStockLocationId)) ||
          (item.product.toStockLocationId && !allowedStockLocationByTag.has(item.product.toStockLocationId)))
      ) {
        return false;
      }

      if (filterProps.product && filterProps.product.size && !filterProps.product.has(item.product.pmdId || '')) {
        return false;
      }

      if (filterProps.user && filterProps.user.size && !filterProps.user.has(item.processedBy || '')) {
        return false;
      }

      return this.search(item, query);
    });

    return result.sort((a, b) =>
      dayjs(new Date(a.processedAt || a.createdAt)).isBefore(new Date(b.processedAt || b.createdAt)) ? 1 : -1,
    );
  }
}

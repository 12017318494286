import React from 'react';
import { Tag } from '../types/tag';

export interface TagContextProps {
  tags: Map<string, Tag>;
  setTags: (tags: Map<string, Tag>) => void;
  tagsLoading: boolean;
}

const defaultContext: TagContextProps = {
  tags: new Map(),
  setTags: () => {},
  tagsLoading: false,
};

export const TagContext = React.createContext<TagContextProps>(defaultContext);

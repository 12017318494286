import React, { JSX } from 'react';
import dayjs from 'dayjs';
import { toFilterString } from '../../../../../util/string.util';
import { FileEntity } from '../../../../../types/file';
import { t } from '../../../../../types/translation/Translator';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { BaseFilter, BaseFilterProps } from '../common/BaseFilter';
import { FilterProps, FilterStorageKey } from '../common/filter.util';
import { FileTypeCategoryFilter } from './FileTypeCategoryFilter';
import { FileTypeFilter } from './FileTypeFilter';

export class FileFilter extends BaseFilter<FileEntity> {
  constructor() {
    super(FilterStorageKey.FILE);
  }

  filterOptions = () => [new FileTypeCategoryFilter(), new FileTypeFilter()];

  toLabel(): string {
    return t().fileType.singular.label;
  }

  toCount(props: BaseFilterProps): number {
    return props.filterProps.fileType?.size || 0;
  }

  toIcon(): JSX.Element {
    return <InsertDriveFileOutlinedIcon className={this.className + ' stroke-none'} />;
  }

  toDropdownContent(): JSX.Element {
    return <></>;
  }

  toInnerContent(): string | JSX.Element {
    return '';
  }

  search(item: FileEntity, textFilter: string) {
    if (toFilterString(item.name).includes(textFilter) || toFilterString(item.type).includes(textFilter)) {
      return true;
    }

    return false;
  }

  filteredItems(items: FileEntity[], query: string, filterProps: FilterProps) {
    return items.filter(item => {
      if (
        filterProps.timeFrame &&
        (dayjs(filterProps.timeFrame.start).startOf('day').isAfter(dayjs(item.createdAt)) ||
          dayjs(filterProps.timeFrame.end).endOf('day').isBefore(dayjs(item.createdAt)))
      ) {
        return false;
      }

      if (filterProps.user && !filterProps.user.has(item.createdBy)) return false;
      if (filterProps.fileType && !filterProps.fileType.has(item.fileType)) return false;
      if (filterProps.fileTypeCategory && !filterProps.fileTypeCategory.has(item.fileTypeCategory)) return false;

      return this.search(item, query);
    });
  }
}

import React from 'react';
import { useContext } from 'react';
import { t } from '../../../../../types/translation/Translator';
import InnerFilterContent, { FilterInnerContentProps } from './DynamicFilterInnerContent';
import { BinStatus } from '../../../../../types/binStatus';
import { BinStatusContext } from '../../../../../context/BinStatusContext';

export default function BinFilterInnerContent({
  filter,
  filterProps,
  setFilterProps,
}: FilterInnerContentProps<BinStatus>) {
  const { binStatuses } = useContext(BinStatusContext);

  return (
    <InnerFilterContent
      filter={filter}
      filterProps={{ filterProps, setFilterProps }}
      items={[...(filterProps.binStatus?.values() || [])]}
      text={item => binStatuses.get(item)?.id || t().unknownBin.singular.label}
      onRemove={i => {
        filterProps.binStatus?.delete(i);
        if (!filterProps.binStatus?.size) filterProps.binStatus = undefined;
        setFilterProps({ ...filterProps });
      }}
    />
  );
}

import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import { CompanyContext } from '../../../../context/CompanyContext';
import { TagContext } from '../../../../context/TagContext';
import {
  CreateTagRelationResponse,
  CreateTagRelationVariables,
  TagRelationMutations,
} from '../../../../graphql/tagRelation.graphql';
import { TagRelationContext } from '../../../../context/TagRelationContext';
import { TagRelation } from '../../../../types/tagRelation';
import CancelButton from '../../../../VentoryUI/components/common/Button/Templates/CancelButton';
import AddButton from '../../../../VentoryUI/components/common/Button/Templates/AddButton';
import Modal from '../../../../VentoryUI/components/common/Modal/Modal';
import { t } from '../../../../types/translation/Translator';
import { EntityType } from '../../../../types/comment';
import { Tag } from '../../../../types/tag';
import DropdownSelect from '../../../Common/DropdownSelect';
import { StockLocation } from '../../../../types/stockLocation';

interface AddStockLocationTagModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  stockLocation?: StockLocation;
}

// TODO: Make generic
export default function AddStockLocationTagModal({ open, setOpen, stockLocation }: AddStockLocationTagModalProps) {
  const { currentCompany } = useContext(CompanyContext);
  const { tags, setTags } = useContext(TagContext);
  const { tagRelations, setTagRelations } = useContext(TagRelationContext);

  const [selected, setSelected] = useState<Tag | undefined>(undefined);
  const [error, setError] = useState<string>('');

  if (!stockLocation) return null;

  const [create, { loading }] = useMutation<CreateTagRelationResponse, CreateTagRelationVariables>(
    TagRelationMutations.create,
    {
      onCompleted: res => {
        const relation = res.createTagRelation[0];
        tagRelations.set(relation.id, new TagRelation(relation));
        setTagRelations(new Map(tagRelations));
        handleClose();
      },
      onError: res => setError(res.message),
    },
  );

  const handleClose = () => {
    setOpen(false);
    setSelected(undefined);
    setError('');
  };

  const handleCreate = async (tag?: Tag) => {
    if (!tag) return;

    try {
      const relation = new TagRelation({
        companyId: currentCompany.id,
        type: EntityType.stockLocation,
        entityId: stockLocation.id,
        tagId: tag.id,
      });

      await create({
        variables: {
          tagRelations: [relation],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const footer = () => (
    <Grid container columnSpacing={1} justifyContent={'flex-end'}>
      <Grid item>
        <CancelButton disabled={loading || !selected} onClick={handleClose} />
      </Grid>
      <Grid item>
        <AddButton loading={loading} disabled={!selected} onClick={() => handleCreate(selected)} />
      </Grid>
    </Grid>
  );

  return (
    <Modal open={open} width='450px' onClose={handleClose} title={t().addTag.singular.label} error={error}>
      <Grid container my={2} px={2}>
        <Grid item xs={12} mb={1}>
          <DropdownSelect
            placeholder={t().selectTag.singular.label}
            values={[...tags.values()].filter(tag => tag.type === EntityType.stockLocation)}
            selectedValue={selected}
            toText={tag => tag?.name || ''}
            onChange={tag => setSelected(tag || undefined)}
          />
        </Grid>
        <Grid item xs={12}>
          {footer()}
        </Grid>
      </Grid>
    </Modal>
  );
}

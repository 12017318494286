import React, { useContext, useEffect, useState } from 'react';
import { FilterProps } from '../../Filter/common/filter.util';
import { Divider, Grid } from '@mui/material';
import { DeleteIcon } from '../../../../icons/Delete/DeleteIcon';
import { VentoryColor } from '../../../../util/color.util';
import { t } from '../../../../../types/translation/Translator';
import MultiSelectionList from '../../../common/MultiSelectionList/MultiSelectionList';
import SearchBar from '../../../common/SearchBar/SearchBar';
import { FileContext } from '../../../../../context/FileContext';
import { toFilterString } from '../../../../../util/string.util';
import ScrollableFilterContent from './ScrollableFilterContent';
import { FileTypeFilter } from '../../Filter/FileFilter/FileTypeFilter';

interface FileTypeFilterContentProps {
  filter: FileTypeFilter;
  filterProps: FilterProps;
  setFilterProps: (filter: FilterProps) => void;
}

const fileTypeFilter = (file: string, text: string, filter: FilterProps) => {
  return toFilterString(file).includes(toFilterString(text));
};

export default function FileTypeFilterContent({ filter, filterProps, setFilterProps }: FileTypeFilterContentProps) {
  const { files } = useContext(FileContext);

  const [textFilter, setTextFilter] = useState<string>('');

  const filterFileTypes = () => {
    return new Set(
      [...files.values()]
        .filter(file => !filterProps.fileTypeCategory?.size || filterProps.fileTypeCategory.has(file.fileTypeCategory))
        .map(file => file.fileType)
        .filter(type => fileTypeFilter(type, textFilter, filterProps)),
    );
  };

  const [values, setValues] = useState<Set<string>>(filterFileTypes());

  const handleChange = (selected: Set<string>) => {
    filterProps.fileType = selected;
    if (!filterProps.fileType?.size) filterProps.fileType = undefined;
    setFilterProps({ ...filterProps });
  };

  const handleRemove = () => {
    filterProps.fileType = undefined;
    setFilterProps({ ...filterProps });
  };

  useEffect(() => {
    setValues(filterFileTypes());
  }, [textFilter]);

  return (
    <ScrollableFilterContent>
      <Grid container>
        <Grid item xs={12} className='flex py-2 px-3'>
          <Grid container>
            <Grid item>
              <p className='text-[13px] font-[500] text-ventory-grey-500'>{t().file.singular.label}</p>
            </Grid>
            <Grid item flexGrow={1} display={'flex'} justifyContent={'flex-end'} sx={{ color: VentoryColor.deleteRed }}>
              <Grid item onClick={handleRemove} className='cursor-pointer'>
                <DeleteIcon />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SearchBar onChange={i => setTextFilter(i)} className='pl-3 pr-4' />
        </Grid>
        <Grid item className='py-3' xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <MultiSelectionList
            selected={filterProps.fileType}
            values={values}
            onChange={handleChange}
            toText={fileType => fileType}
          />
        </Grid>
      </Grid>
    </ScrollableFilterContent>
  );
}

import { t } from '../../../../../types/translation/Translator';
import React from 'react';
import TagLabel, { TagLabelProps } from '../TagLabel';
import AddIcon from '@mui/icons-material/Add';

export function AddTagTemplate(props: AddTagLabelProps): TagLabelProps {
  return {
    color: '#717171',
    backgroundColor: 'white',
    text: t().add.singular.label,
    suffix: <AddIcon sx={{ width: '18px', height: '18px' }} />,
    dashed: true,
    ...props,
  };
}

interface AddTagLabelProps {
  onClick: () => Promise<void>;
}

export default function AddTagLabel({ ...props }: AddTagLabelProps) {
  return <TagLabel {...AddTagTemplate(props)} />;
}

import React from 'react';
import { BaseFilter, BaseFilterProps } from '../common/BaseFilter';
import { t } from '../../../../../types/translation/Translator';
import { ListIcon } from '../../../../icons/List/ListIcon';
import { StockLocation } from '../../../../../types/stockLocation';
import TaskTypeFilterInnerContent from '../../DynamicEntityFilter/InnerContent/TaskTypeFilterInnerContent';
import TaskTypeFilterDropdownContent from '../../DynamicEntityFilter/Content/TaskTypeFitlerContent';
import { FilterProps } from '../common/filter.util';
import { TaskType } from '../../../../../types/task';
import { toFilterString } from '../../../../../util/string.util';

export class TaskTypeFilter extends BaseFilter<TaskType> {
  stockLocations: Map<string, StockLocation>;

  constructor(stockLocations: Map<string, StockLocation>) {
    super();
    this.stockLocations = stockLocations;
  }

  toLabel(): string {
    return t().taskType.singular.label;
  }

  toCount(props: BaseFilterProps): number {
    return props.filterProps.taskType?.size || 0;
  }

  toIcon(): JSX.Element {
    return <ListIcon className={this.className} />;
  }

  toDropdownContent(props: BaseFilterProps): JSX.Element {
    return (
      <TaskTypeFilterDropdownContent
        filter={this}
        filterProps={props.filterProps}
        setFilterProps={props.setFilterProps}
      />
    );
  }

  toInnerContent(props: BaseFilterProps): string | JSX.Element {
    return (
      <TaskTypeFilterInnerContent filter={this} filterProps={props.filterProps} setFilterProps={props.setFilterProps} />
    );
  }

  search(item: TaskType, filter: string) {
    if (toFilterString(item).includes(filter)) {
      return true;
    }
    return false;
  }

  filteredItems(items: TaskType[], query: string, filterProps: FilterProps): TaskType[] {
    return items.filter(item => {
      return this.search(item, query);
    });
  }
}

import React from 'react';
import { Tag } from '../../../../../types/tag';
import { BaseFilter, BaseFilterProps } from '../common/BaseFilter';
import { t } from '../../../../../types/translation/Translator';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import TagFilterDropdownContent from '../../DynamicEntityFilter/Content/TagFilterContent';
import TagFilterInnerContent from '../../DynamicEntityFilter/InnerContent/TagFilterInnerContent';
import { toFilterString } from '../../../../../util/string.util';

export class TagFilter extends BaseFilter<Tag> {
  toLabel(): string {
    return t().tag.plural.label;
  }

  toIcon(): React.JSX.Element {
    return <LocalOfferOutlinedIcon sx={{ width: '15px', height: '15px' }} />;
  }

  toDropdownContent(props: BaseFilterProps): React.JSX.Element {
    return (
      <TagFilterDropdownContent filter={this} filterProps={props.filterProps} setFilterProps={props.setFilterProps} />
    );
  }

  toInnerContent(props: BaseFilterProps): string | JSX.Element {
    return (
      <TagFilterInnerContent filter={this} filterProps={props.filterProps} setFilterProps={props.setFilterProps} />
    );
  }

  toCount(props: BaseFilterProps): number {
    return props.filterProps.tags?.size || 0;
  }

  search(item: Tag, textFilter: string) {
    if (toFilterString(item.name).includes(textFilter)) {
      return true;
    }

    return false;
  }
}

import { Grid } from '@mui/material';
import { SectionTitle } from '../SectionTitle/SectionTitle';
import React, { useContext, useState } from 'react';
import { FilterProps, FilterStorageKey, getFilterCount } from '../../filters/Filter/common/filter.util';
import { NumberChartContainer } from '../../../../components/Dashboard/Graphs/Containers/NumberChartContainer';
import { NumberChartType } from '../../charts/NumberChart/NumberChart';
import { RadialChartContainer } from '../../../../components/Dashboard/Graphs/Containers/RadialChartContainer';
import { BarChartContainer } from '../../../../components/Dashboard/Graphs/Containers/BarChartContainer';
import { t } from '../../../../types/translation/Translator';
import FilterModal from '../SearchBarWithFilter/FilterModal';
import FilterIcon from '../../../icons/Filter/FilterIcon';
import { Button } from '../Button/Button';
import { TaskFilter } from '../../filters/Filter/Task/TaskFilter';
import { StockLocationContext } from '../../../../context/StockLocationContext';
import { UserContext } from '../../../../context/UserContext';
import { BinStatusContext } from '../../../../context/BinStatusContext';
import { ProductMasterDataContext } from '../../../../context/ProductMasterDataContext';
import { TagRelationContext } from '../../../../context/TagRelationContext';

interface TaskMetricsProps {
  color: string;
}

export default function TaskMetrics({ color }: TaskMetricsProps) {
  const { filteredStockLocations } = useContext(StockLocationContext);
  const { companyUsers } = useContext(UserContext);
  const { binStatuses } = useContext(BinStatusContext);
  const { productMasterData } = useContext(ProductMasterDataContext);
  const { tagRelations } = useContext(TagRelationContext);

  const [filterProps, setFilterProps] = useState<FilterProps>(new FilterProps(undefined, undefined));

  const [filterModalOpen, setFilterModalOpen] = useState(false);

  return (
    <>
      <FilterModal
        open={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        filter={new TaskFilter(filteredStockLocations, companyUsers, binStatuses, productMasterData, tagRelations)}
        filterProps={filterProps}
        setFilterProps={setFilterProps}
      />

      <Grid item xs={12} mt={1}>
        <SectionTitle text={t().taskMetrics.singular.label} />
      </Grid>
      <Grid item xs={12} mb={1}>
        <Grid container>
          <Grid item>
            <Button
              startIcon={<FilterIcon />}
              text={t().filter.singular.label}
              badge={getFilterCount(filterProps)}
              onClick={() => setFilterModalOpen(true)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} display={'flex'}>
        <Grid container columnSpacing={1}>
          <Grid item lg={6} md={6} sm={12} xs={12} display={'flex'}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container columnSpacing={1}>
                  <NumberChartContainer
                    filter={filterProps}
                    entity={FilterStorageKey.TASK}
                    color={color}
                    type={NumberChartType.text}
                    size={{ xs: 6, sm: 6, md: 6, lg: 6 }}
                    text={t().total.singular.label}
                  />
                  <NumberChartContainer
                    filter={filterProps}
                    entity={FilterStorageKey.TASK}
                    color={color}
                    type={NumberChartType.text}
                    size={{ xs: 6, sm: 6, md: 6, lg: 6 }}
                    text={t().complete.singular.label}
                    additionalFilter={'status = complete OR status = processed'}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container columnSpacing={1}>
                  <NumberChartContainer
                    filter={filterProps}
                    entity={FilterStorageKey.TASK}
                    color={color}
                    type={NumberChartType.text}
                    size={{ xs: 4, sm: 4, md: 4, lg: 6 }}
                    text={t().inProgress.singular.label}
                    additionalFilter={'status = inProgress'}
                  />
                  <NumberChartContainer
                    filter={filterProps}
                    entity={FilterStorageKey.TASK}
                    color={color}
                    type={NumberChartType.text}
                    size={{ xs: 4, sm: 4, md: 4, lg: 3 }}
                    text={t().overdue.singular.label}
                    additionalFilter={'overdue = true'}
                  />
                  <NumberChartContainer
                    filter={filterProps}
                    entity={FilterStorageKey.TASK}
                    color={color}
                    type={NumberChartType.text}
                    size={{ xs: 4, sm: 4, md: 4, lg: 3 }}
                    text={t().recount.singular.label}
                    additionalFilter={'parentId != null'}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6} md={6} sm={12} display={'flex'}>
            <Grid container columnSpacing={1} display={'flex'} className='h-full'>
              <Grid item xs={6} className='h-full'>
                <RadialChartContainer
                  text={t().binMetrics.singular.label}
                  entity={FilterStorageKey.BIN_FOR_TASK}
                  color={color}
                  filter={filterProps}
                />
              </Grid>
              <Grid item xs={6} className='h-full'>
                <RadialChartContainer
                  text={t().productMetrics.singular.label}
                  entity={FilterStorageKey.PRODUCT_FOR_TASK}
                  color={color}
                  filter={filterProps}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className='min-h-[400px]'>
        <BarChartContainer
          text={t().numberOfCounts.singular.label}
          entity={FilterStorageKey.TASK}
          color={color}
          filter={filterProps}
          className='min-h-[400px]'
        />
      </Grid>
    </>
  );
}

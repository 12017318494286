import React, { useContext, useState } from 'react';
import { t } from '../../../../types/translation/Translator';
import { CompanyContext } from '../../../../context/CompanyContext';
import {
  BarcodeFormat,
  Company,
  ScanConfiguration,
  ScanConfigurationType,
  barcodeFormatToString,
  scanConfigurationTypeToString,
} from '../../../../types/company';
import CreateBarcodeConfigurationModal from './BarcodeConfigurations/Modal/CreateBarcodeConfigurationModal';
import UpdateBarcodeConfigurationModal from './BarcodeConfigurations/Modal/UpdateBarcodeConfigurationModal';
import SearchBarWithFilter from '../../../../VentoryUI/components/common/SearchBarWithFilter/SearchBarWithFilter';
import { ScanConfigurationFilter } from '../../../../VentoryUI/components/filters/Filter/Scan/ScanConfigurationFilter';
import { AddButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/AddButton';
import { FlexPane } from '../../../../VentoryUI/components/common/FlexPane/FlexPane';
import Table from '../../../../VentoryUI/components/common/Table/Table';

interface CompanyBarcodeConfigurationsPaneInputProps {
  footer: (company: Company) => JSX.Element;
}

export default function CompanyBarcodeConfigurationsePane({ footer }: CompanyBarcodeConfigurationsPaneInputProps) {
  const { currentCompany } = useContext(CompanyContext);

  const [companyInput, setCompanyInput] = useState<Company>(new Company(currentCompany));

  const [items, setItems] = useState(companyInput.settings.scanConfigurations || []);

  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<number | null>(null);

  const headers = [
    {
      key: 'type',
      name: 'Type',
      text: (item: ScanConfiguration) => scanConfigurationTypeToString(item.type),
    },
    {
      key: 'format',
      name: 'Barcode Format',
      text: (item: ScanConfiguration) => barcodeFormatToString(item.barcodeFormat),
    },
    {
      key: 'regex',
      name: 'Regex',
      text: (item: ScanConfiguration) => item.regex,
    },
  ];

  return (
    <>
      <CreateBarcodeConfigurationModal
        open={openCreateModal}
        setOpen={(v, config) => {
          setOpenCreateModal(v);
          if (!config) return;
          if (!companyInput.settings.scanConfigurations) companyInput.settings.scanConfigurations = [];
          companyInput.settings.scanConfigurations.push(config);
          setCompanyInput(new Company(companyInput));
        }}
        configuration={{
          regex: '',
          regexOption: '',
          barcodeFormat: BarcodeFormat.code128,
          type: ScanConfigurationType.productNumber,
        }}
      />
      {selected !== null && selected > -1 && companyInput.settings.scanConfigurations ? (
        <UpdateBarcodeConfigurationModal
          open={openUpdateModal}
          setOpen={(v, config, remove) => {
            setOpenUpdateModal(v);
            if (!config) return;
            if (!companyInput.settings.scanConfigurations) companyInput.settings.scanConfigurations = [];
            if (remove) {
              companyInput.settings.scanConfigurations.splice(selected, 1);
            } else {
              companyInput.settings.scanConfigurations[selected] = config;
            }
            setCompanyInput(new Company(companyInput));
            setSelected(null);
          }}
          configuration={companyInput.settings.scanConfigurations[selected]}
        />
      ) : null}

      <FlexPane
        header={
          <SearchBarWithFilter
            items={companyInput.settings.scanConfigurations || []}
            setItems={setItems}
            placeholder={t().filterBarcodeConfigurations.singular.label}
            buttons={[AddButtonTemplate(() => setOpenCreateModal(true))]}
            filter={new ScanConfigurationFilter()}
          />
        }
        content={
          <Table
            loading={false}
            items={items}
            headers={headers}
            onClick={item => {
              setSelected([...(companyInput.settings.scanConfigurations || [])].findIndex(i => i === item));
              setOpenUpdateModal(true);
            }}
          />
        }
        footer={footer(companyInput)}
      />
    </>
  );
}

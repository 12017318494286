import React from 'react';
import { Trigger } from '../../../../../types/trigger';
import { toFilterString } from '../../../../../util/string.util';
import { BaseFilter, BaseFilterProps } from '../common/BaseFilter';

export class TriggerFilter extends BaseFilter<Trigger> {
  triggerTexts: Map<string, string>;

  constructor(triggerTexts: Map<string, string>) {
    super();
    this.triggerTexts = triggerTexts;
  }

  search(item: Trigger, query: string): boolean {
    if (toFilterString(this.triggerTexts.get(item.id)).includes(query)) {
      return true;
    }

    return false;
  }

  toLabel(props: BaseFilterProps): string {
    throw new Error('Method not implemented.');
  }

  toInnerContent(props: BaseFilterProps): string | JSX.Element {
    throw new Error('Method not implemented.');
  }

  toDropdownContent(props: BaseFilterProps): React.JSX.Element {
    throw new Error('Method not implemented.');
  }

  toIcon(props: BaseFilterProps): React.JSX.Element {
    throw new Error('Method not implemented.');
  }
  toCount(props: BaseFilterProps): number {
    throw new Error('Method not implemented.');
  }
}

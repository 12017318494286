import React from 'react';
import { FilterProps, FilterStorageKey } from './common/filter.util';
import { BaseFilter, BaseFilterProps } from './common/BaseFilter';
import { Product } from '../../../../types/product';
import { t } from '../../../../types/translation/Translator';
import { ProductIcon } from '../../../icons/Product/ProductIcon';
import { removeDiacritics, toFilterString } from '../../../../util/string.util';
import { ProductMasterData } from '../../../../types/productMasterData';
import { BinFilter } from './Bin/BinFilter';
import { BinStatus } from '../../../../types/binStatus';
import { StockLocationFilter } from './common/StockLocationFilter';
import { TagFilter } from './Tag/TagFilter';
import { TagRelation } from '../../../../types/tagRelation';
import { EntityType } from '../../../../types/comment';

export class ProductFilter extends BaseFilter<Product> {
  productMasterData: Map<string, ProductMasterData>;
  binStatuses: Map<string, BinStatus>;
  tagRelations: Map<string, TagRelation>;

  constructor(
    productMasterData: Map<string, ProductMasterData>,
    binStatuses: Map<string, BinStatus>,
    tagRelations: Map<string, TagRelation>,
  ) {
    super(FilterStorageKey.PRODUCT);
    this.productMasterData = productMasterData;
    this.binStatuses = binStatuses;
    this.tagRelations = tagRelations;
  }

  filterOptions = () => [
    new StockLocationFilter(this.tagRelations),
    new BinFilter(this.binStatuses, this.tagRelations),
    new TagFilter(),
  ];

  toLabel = () => {
    return t().product.singular.label;
  };

  toCount(props: BaseFilterProps) {
    return props.filterProps.product?.size || 0;
  }

  toDropdownContent(props: BaseFilterProps) {
    return <></>;
  }

  toInnerContent = (props: BaseFilterProps) => {
    return <></>;
  };

  toIcon = () => {
    return <ProductIcon className={this.className} />;
  };

  search = (item: Product, filter: string) => {
    const productName = this.productMasterData.get(item.productMasterDataId)?.productName;
    const productNumber = this.productMasterData.get(item.productMasterDataId)?.productNumber;

    if (
      removeDiacritics(toFilterString(productName)).includes(removeDiacritics(filter)) ||
      removeDiacritics(toFilterString(productNumber)).includes(removeDiacritics(filter))
    ) {
      return true;
    }
    return false;
  };

  filteredItems(items: Product[], query: string, filterProps: FilterProps): Product[] {
    const allowedStockLocationByTag = new Set<string>();
    if (filterProps.tags?.size) {
      for (const [key, relation] of this.tagRelations) {
        if (filterProps.tags.has(relation.tagId)) {
          if (relation.type === EntityType.stockLocation) allowedStockLocationByTag.add(relation.entityId);
        }
      }
    }

    return items.filter(item => {
      if (
        filterProps.stockLocation &&
        filterProps.stockLocation.size &&
        !filterProps.stockLocation.has(item.stockLocationId)
      ) {
        return false;
      }

      if (allowedStockLocationByTag.size && !allowedStockLocationByTag.has(item.stockLocationId)) {
        return false;
      }

      if (filterProps.bin && filterProps.bin.size && !filterProps.bin.has(item.binId)) {
        return false;
      }

      return this.search(item, query);
    });
  }
}

import React, { useContext, useMemo, useState } from 'react';
import { t } from '../../../types/translation/Translator';
import { ContactContext } from '../../../context/ContactContext';
import { Contact } from '../../../types/contact';
import { useNavigate } from 'react-router-dom';
import CreateContactModal from './Modals/CreateContactModal';
import { testIds } from '../../../util/identifiers/identifiers.util';
import { UserContext } from '../../../context/UserContext';
import { CompanyRoleAssignmentContext } from '../../../context/CompanyRoleAssignmentContext';
import { CompanyContext } from '../../../context/CompanyContext';
import { CompanyRole } from '../../../types/companyRoleAssignment';
import { SuperUserContext } from '../../../context/SuperUserContext';
import SearchBarWithFilter from '../../../VentoryUI/components/common/SearchBarWithFilter/SearchBarWithFilter';
import { NewMenuItemTemplate } from '../../../VentoryUI/components/common/Menu/Templates/NewMenuItem';
import { FlexPane } from '../../../VentoryUI/components/common/FlexPane/FlexPane';
import Table from '../../../VentoryUI/components/common/Table/Table';
import { ContactFilter } from '../../../VentoryUI/components/filters/Filter/common/ContactFilter';

export default function ContactOverviewPane() {
  const navigate = useNavigate();

  const { contacts, contactsLoading } = useContext(ContactContext);
  const { companyRoles } = useContext(CompanyRoleAssignmentContext);
  const { currentUser } = useContext(UserContext);
  const { currentCompany } = useContext(CompanyContext);
  const { superUser } = useContext(SuperUserContext);

  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);

  const [items, setItems] = useState([...contacts.values()]);

  const headers = [
    {
      key: 'name',
      name: 'Name',
      text: (item: Contact) => item.name,
    },
    {
      key: 'email',
      name: 'Email',
      text: (item: Contact) => item.email || '',
    },
  ];

  const menuItems = useMemo(() => {
    const role = companyRoles.get(currentUser?.userId || '')?.find(cr => cr.companyId === currentCompany.id);

    if (role?.role === CompanyRole.administrator || superUser)
      return [NewMenuItemTemplate(() => setOpenCreateModal(true))];

    return [];
  }, [companyRoles]);

  const allItems = useMemo(() => {
    return [...contacts.values()];
  }, [contacts]);

  return (
    <>
      <CreateContactModal open={openCreateModal} setOpen={setOpenCreateModal} />
      <FlexPane
        testId={testIds.contactOverviewPane}
        header={
          <SearchBarWithFilter
            loading={contactsLoading}
            placeholder={t().filterContacts.singular.label}
            menuItems={menuItems}
            items={allItems}
            setItems={setItems}
            filter={new ContactFilter()}
          />
        }
        content={
          <Table
            loading={contactsLoading}
            items={items}
            totalItemCount={allItems.length}
            headers={headers}
            onClick={item => navigate(`/operations/contacts/${item.id}/update`)}
          />
        }
      />
    </>
  );
}

import React, { useContext, useEffect, useState } from 'react';
import { NumberChart, NumberChartType } from '../../../../VentoryUI/components/charts/NumberChart/NumberChart';
import { CompanyContext } from '../../../../context/CompanyContext';
import { DashboardContext, getEntityDataCacheKey } from '../../../../context/DashboardContext';
import {
  DashboardQueries,
  GetDashboardFilterResponse,
  GetDashboardFilterVariables,
  toFilterParameters,
} from '../../../../graphql/dashboard.graphql';
import { useQuery } from '@apollo/client';
import { DashboardData, DashboardSize } from '../../Common/dashboard.util';
import { FilterProps, FilterStorageKey } from '../../../../VentoryUI/components/filters/Filter/common/filter.util';
import { FilterParser } from '../../Common/Filters/FilterParser';

interface NumberChartContainerProps {
  filter: FilterProps;
  text: string;
  entity: FilterStorageKey;
  color: string;
  size: DashboardSize;
  type: NumberChartType;
  additionalFilter?: string;
  className?: string;
}

export function NumberChartContainer({
  text,
  size,
  entity,
  color,
  type,
  filter,
  additionalFilter,
  className,
}: NumberChartContainerProps) {
  const { currentCompany } = useContext(CompanyContext);
  const { cache, setCache } = useContext(DashboardContext);

  const [data, setData] = useState<DashboardData>({ filteredChartData: [], total: 0 });

  const cacheKey = getEntityDataCacheKey(entity, filter, FilterParser.parse(additionalFilter));
  const hasCachedData = cache.has(cacheKey);

  const { loading } = useQuery<GetDashboardFilterResponse, GetDashboardFilterVariables>(DashboardQueries.get, {
    skip: hasCachedData,
    variables: {
      companyId: currentCompany.id,
      locale: (window.navigator as any)['userLanguage'] || window.navigator.language,
      parameters: toFilterParameters(entity, filter, FilterParser.parse(additionalFilter)),
    },
    onCompleted: res => {
      cache.set(cacheKey, res.dashboardQuery);
      setCache(new Map(cache));

      setData(res.dashboardQuery);
    },
    onError: err => console.log(err),
  });

  useEffect(() => {
    if (hasCachedData) setData(cache.get(cacheKey)!);
  }, [cache, filter]);

  const value = data.filteredChartData.reduce((sum, a) => sum + a.value, 0);
  const max = data.total;

  return (
    <NumberChart
      className={className}
      size={size}
      text={text}
      value={value}
      max={max}
      color={color}
      loading={loading}
      type={type}
    />
  );
}

import React, { useContext, useState } from 'react';
import { CompanyContext } from '../../../../context/CompanyContext';
import { t } from '../../../../types/translation/Translator';
import CreateOrderInfoPane from './Panes/CreateOrderInfoPane';
import { CreateOrderInput, Order } from '../../../../types/order';
import CreateOrderProductPane from './Panes/CreateOrderProductPane';
import CreateOrderUserPane from './Panes/CreateOrderUserPane';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CreateOrderResponse, CreateOrderVariables, OrderMutations } from '../../../../graphql/order.graphql';
import { OrderContext } from '../../../../context/OrderContext';
import CreateOrderFieldsPane from './Panes/CreateOrderFieldsPane';
import { testIds } from '../../../../util/identifiers/identifiers.util';

import {
  GetProductTransactionsResponse,
  GetProductTransactionsVariables,
  ProductTransactionQueries,
} from '../../../../graphql/productTransaction.graphql';
import { ProductTransactionContext } from '../../../../context/ProductTransactionContext';
import { ProductTransaction } from '../../../../types/productTransaction';
import Modal from '../../../../VentoryUI/components/common/Modal/Modal';

interface CreateOrderModalInputProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

enum CreateOrderState {
  info = 'info',
  product = 'product',
  fields = 'fields',
  user = 'user',
}

export default function CreateOrderModal({ open, setOpen }: CreateOrderModalInputProps) {
  if (!open) return null;

  const { currentCompany } = useContext(CompanyContext);
  const { orders, setOrders } = useContext(OrderContext);
  const { productTransactions, setProductTransactions } = useContext(ProductTransactionContext);

  const [orderInput, setOrderInput] = useState<CreateOrderInput>(
    new CreateOrderInput({ companyId: currentCompany.id }),
  );

  const [currentPane, setCurrentPane] = useState<CreateOrderState>(CreateOrderState.info);
  const [error, setError] = useState<string>('');

  const [create, { loading }] = useMutation<CreateOrderResponse, CreateOrderVariables>(OrderMutations.create, {
    onCompleted: res => {
      res.createOrder.forEach(order => orders.set(order.id, new Order(order)));
      setOrders(new Map(orders));
    },
    onError: err => setError(err.message),
  });

  const [fetchProductTransactions, { loading: productTransactionsLoading }] = useLazyQuery<
    GetProductTransactionsResponse,
    GetProductTransactionsVariables
  >(ProductTransactionQueries.get, {
    variables: {
      companyId: currentCompany.companyId,
      batchSize: orderInput.products.size < 100 ? 100 : orderInput.products.size,
    },
    onCompleted: async res => {
      res.productTransactions.data.forEach(pt => productTransactions.set(pt.id, new ProductTransaction(pt)));
      setProductTransactions(new Map(productTransactions));

      if (!error) handleClose();
    },
    onError: error => setError(error.message),
  });

  const handleClose = () => {
    setCurrentPane(CreateOrderState.info);
    setOrderInput(new CreateOrderInput(new CreateOrderInput({ companyId: currentCompany.id })));
    setOpen(false);
  };

  const productSelectionFirst = currentCompany.settings.featureToggles.orders.productSelectionFirst;

  const handleNext = () => {
    switch (currentPane) {
      case CreateOrderState.info:
        return setCurrentPane(CreateOrderState.product);
      case CreateOrderState.product:
        if (productSelectionFirst) return setCurrentPane(CreateOrderState.fields);
        if (orderInput.stockLocationId.includes('tag::')) return handleFinish();
        return setCurrentPane(CreateOrderState.user);
      case CreateOrderState.fields:
        return setCurrentPane(CreateOrderState.user);
    }
  };

  const handleFinish = async () => {
    try {
      const input = orderInput.forCreate();
      await create({
        variables: {
          orders: [input],
        },
      });

      await fetchProductTransactions();
    } catch (e) {
      setError(String(e));
    }
  };

  const handleBack = () => {
    switch (currentPane) {
      case CreateOrderState.info:
        return;
      case CreateOrderState.product:
        return setCurrentPane(CreateOrderState.info);
      case CreateOrderState.fields:
        return setCurrentPane(CreateOrderState.product);
      case CreateOrderState.user:
        if (productSelectionFirst) return setCurrentPane(CreateOrderState.fields);
        return setCurrentPane(CreateOrderState.product);
    }
  };

  const content = () => {
    switch (currentPane) {
      case CreateOrderState.info:
        return <CreateOrderInfoPane order={orderInput} setOrder={setOrderInput} next={handleNext} />;
      case CreateOrderState.product:
        return (
          <CreateOrderProductPane
            order={orderInput}
            setOrder={setOrderInput}
            setError={setError}
            next={handleNext}
            back={handleBack}
            modalHeight={600}
            loading={loading}
          />
        );
      case CreateOrderState.fields:
        return (
          <CreateOrderFieldsPane order={orderInput} setOrder={setOrderInput} next={handleNext} back={handleBack} />
        );
      case CreateOrderState.user:
        return (
          <CreateOrderUserPane
            order={orderInput}
            setOrder={setOrderInput}
            next={handleFinish}
            back={handleBack}
            loading={loading || productTransactionsLoading}
          />
        );
    }
  };

  return (
    <Modal
      error={error}
      open={open}
      onClose={handleClose}
      height='620px'
      title={t().createOrder.singular.label}
      testId={testIds.createOrderModal}
    >
      {content()}
    </Modal>
  );
}

import React, { useContext, useMemo, useState } from 'react';
import { t } from '../../../../types/translation/Translator';

import { CompanyContext } from '../../../../context/CompanyContext';
import SearchBarWithFilter from '../../../../VentoryUI/components/common/SearchBarWithFilter/SearchBarWithFilter';
import { AddButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/AddButton';
import { FlexPane } from '../../../../VentoryUI/components/common/FlexPane/FlexPane';
import Table from '../../../../VentoryUI/components/common/Table/Table';
import { TagContext } from '../../../../context/TagContext';
import { TagFilter } from '../../../../VentoryUI/components/filters/Filter/Tag/TagFilter';
import { Tag } from '../../../../types/tag';
import { entityTypeToString } from '../../../../types/comment';
import CreateTagModal from '../Modals/TagPane/CreateTagModal';
import UpdateTagModal from '../Modals/TagPane/UpdateTagModal';

interface TagPaneProps {
  setError: (error: string) => void;
}

export default function TagPane({ setError }: TagPaneProps) {
  const { currentCompany } = useContext(CompanyContext);
  const { tags, tagsLoading } = useContext(TagContext);

  const [selected, setSelected] = useState<Tag>(new Tag({ companyId: currentCompany.id }));
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);

  const [items, setItems] = useState([...tags.values()]);

  const headers = [
    {
      key: 'name',
      name: t().name.singular.label,
      text: (item: Tag) => item.name,
    },
    {
      key: 'type',
      name: t().type.singular.label,
      text: (item: Tag) => entityTypeToString(item.type),
    },
  ];

  const handleOpenUpdateModal = () => {
    setOpenUpdateModal(false);
    setSelected(new Tag({ companyId: currentCompany.id }));
  };

  const allItems = useMemo(() => {
    return [...tags.values()];
  }, [tags]);

  return (
    <>
      <CreateTagModal open={openCreateModal} setOpen={setOpenCreateModal} />
      <UpdateTagModal open={openUpdateModal} setOpen={setOpenUpdateModal} tag={selected} />

      <FlexPane
        header={
          <SearchBarWithFilter
            items={allItems}
            setItems={setItems}
            placeholder={t().filterTag.plural.label}
            buttons={[AddButtonTemplate(() => setOpenCreateModal(true))]}
            filter={new TagFilter()}
          />
        }
        content={
          <Table
            loading={tagsLoading}
            items={items}
            totalItemCount={allItems.length}
            headers={headers}
            onClick={item => {
              setSelected(item);
              setOpenUpdateModal(true);
            }}
          />
        }
      />
    </>
  );
}

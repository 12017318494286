import React, { useContext, useEffect, useState } from 'react';
import { FilterProps } from '../../Filter/common/filter.util';
import { Divider, Grid } from '@mui/material';
import { DeleteIcon } from '../../../../icons/Delete/DeleteIcon';
import { VentoryColor } from '../../../../util/color.util';
import { t } from '../../../../../types/translation/Translator';
import { StockLocationContext } from '../../../../../context/StockLocationContext';
import MultiSelectionList from '../../../common/MultiSelectionList/MultiSelectionList';
import SearchBar from '../../../common/SearchBar/SearchBar';
import ScrollableFilterContent from './ScrollableFilterContent';
import { BinStatusFilter } from '../../Filter/Bin/BinStatusFilter';
import { BinStatusContext } from '../../../../../context/BinStatusContext';

interface BinStatusFilterDropdownContentProps {
  filter: BinStatusFilter;
  filterProps: FilterProps;
  setFilterProps: (filter: FilterProps) => void;
}

export default function BinStatusFilterDropdownContent({
  filter,
  filterProps,
  setFilterProps,
}: BinStatusFilterDropdownContentProps) {
  const { stockLocations } = useContext(StockLocationContext);
  const { binStatuses } = useContext(BinStatusContext);

  const [query, setQuery] = useState<string>('');

  const filterBinStatuses = () => {
    return new Set(filter.filteredItems([...binStatuses.values()], query, filterProps).map(binStatus => binStatus.id));
  };

  const [values, setValues] = useState<Set<string>>(filterBinStatuses());

  const handleChange = (selected: Set<string>) => {
    filterProps.binStatus = selected;
    if (!filterProps.binStatus?.size) filterProps.binStatus = undefined;
    setFilterProps({ ...filterProps });
  };

  const handleRemove = () => {
    filterProps.binStatus = undefined;
    setFilterProps({ ...filterProps });
  };

  useEffect(() => {
    setValues(filterBinStatuses());
  }, [query]);

  return (
    <ScrollableFilterContent>
      <Grid container>
        <Grid item xs={12} className='flex py-2 px-3'>
          <Grid container>
            <Grid item>
              <p className='text-[13px] font-[500] text-ventory-grey-500'>{t().bin.singular.label}</p>
            </Grid>
            <Grid item flexGrow={1} display={'flex'} justifyContent={'flex-end'} sx={{ color: VentoryColor.deleteRed }}>
              <Grid item onClick={handleRemove} className='cursor-pointer'>
                <DeleteIcon />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SearchBar onChange={i => setQuery(i)} className='pl-3 pr-4' />
        </Grid>
        <Grid item className='py-3' xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <MultiSelectionList
            selected={filterProps.binStatus}
            values={values}
            onChange={handleChange}
            toText={id => `${binStatuses.get(id)?.status}`}
            // toSubText={id => `${stockLocations.get(binStatuses.get(id)?. || '')?.name || ''}`}
          />
        </Grid>
      </Grid>
    </ScrollableFilterContent>
  );
}

import React from 'react';
import { Lot } from '../../../../../types/lot';
import { toFilterString } from '../../../../../util/string.util';
import { BaseFilter, BaseFilterProps } from '../common/BaseFilter';
import { t } from '../../../../../types/translation/Translator';
import BoxIcon from '../../../../icons/Box/BoxIcon';
import LotFilterInnerContent from '../../DynamicEntityFilter/InnerContent/LotFilterInnerContext';
import LotFilterContent from '../../DynamicEntityFilter/Content/LotFilterContent';
import { FilterProps } from './filter.util';

export class LotFilter extends BaseFilter<Lot> {
  toLabel = () => {
    return t().lot.singular.label;
  };

  toInnerContent(props: BaseFilterProps): string | JSX.Element {
    return (
      <LotFilterInnerContent filter={this} filterProps={props.filterProps} setFilterProps={props.setFilterProps} />
    );
  }

  toDropdownContent(props: BaseFilterProps): React.JSX.Element {
    return <LotFilterContent filter={this} filterProps={props.filterProps} setFilterProps={props.setFilterProps} />;
  }

  toIcon(): React.JSX.Element {
    return <BoxIcon className={this.className} />;
  }

  toCount(props: BaseFilterProps): number {
    return props.filterProps.lot?.size || 0;
  }

  search(item: Lot, textFilter: string) {
    if (toFilterString(item.number).includes(textFilter)) {
      return true;
    }

    return false;
  }

  filteredItems(items: Lot[], searchInput: string, filterProps: FilterProps) {
    const result = items.filter(item => {
      return this.search(item, searchInput);
    });

    return result;
  }
}
